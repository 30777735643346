import React, { useContext, useEffect, useState } from 'react';
import Context from './Context';
import { PopSvg } from './PopSvg';


function splitToolItems(arr, size) {
    var newArr = [];
    if (!size || (size === 0)) return newArr;
    for (let i=0; i<arr.length; i+=0) { 
        var sliced = arr.slice(i, size);
        newArr.push(sliced);
        arr.splice(0, size);
    }
    return newArr;
  }

function calcMaxItems(width) {
    return (Math.trunc((window.innerWidth * 0.95 - 20)/(window.innerWidth < 600 ? 50: 90)));
}


function PopToolBox() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config } = globalState;
    const [maxItems, setMaxItems] = useState(calcMaxItems(window.innerWidth));
    const toolItemsToShow = splitToolItems(config.toolItems.filter(item => item.itemGroup !== 3 && item.itemUse(globalState)), maxItems);

    const callSetMaxItems = () => setMaxItems(calcMaxItems(window.innerWidth));    

      useEffect(() => {
        window.addEventListener('resize', callSetMaxItems);
        
        return(() => {
            window.removeEventListener('resize', callSetMaxItems);
        })
      }, [maxItems])

    return (
        globalState.navigation.editMode ? 
        <div className = "pop-tool">
            <div className = "pop-tool-options">
                { toolItemsToShow.map((toolItemsLine, index) => (
                    <div className = "pop-tool-options-line" key={index}>
                        { toolItemsLine.map((toolItem, index) => (                   
                        <div key={index} className = "pop-tool-item"  onClick = {() => toolItem.itemOnClick(globalState, globalDispatch)}>
                            <PopSvg svg={typeof toolItem.itemSvg === "string" ? toolItem.itemSvg : toolItem.itemSvg(globalState)}/>
                            <p>{toolItem.itemName}</p>
                        </div> 
                        )) }     
                    </div> ))}
            </div>      
        </div> : null
    );

}



export default PopToolBox;