import React, { useContext, useState, useRef, useEffect } from 'react';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';
import PopCardAttribute from './PopAttribute';
import { pop } from './PopConst';
import useDoubleClick from 'use-double-click';


function distXY(x, y) {
    return Math.sqrt(x*x+y*y);
}

function fillRectInvers(ctx, crop, width, height) {
    var path = new Path2D();
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = '#000'
    path.moveTo(0,0); path.lineTo(width, 0); path.lineTo(width, height); path.lineTo(0, height); path.lineTo(0,0); path.closePath();
    path.moveTo(crop.left, crop.top + crop.height); path.lineTo(crop.left + crop.width, crop.top + crop.height); 
    path.lineTo(crop.left + crop.width, crop.top); path.lineTo(crop.left, crop.top); path.closePath();
    ctx.fill(path);
    ctx.globalAlpha = 1.0;
}

function cropCenter(crop) {
    return ({ x: crop.left + crop.width / 2, y: crop.top + crop.height / 2 });
}

function cropRightBottom(crop) {
    return ({ right: crop.left + crop.width, bottom: crop.top + crop.height });
}


function findPoint(x, y, crop0) {
    const crop = { ...crop0, ...cropCenter(crop0), ...cropRightBottom(crop0) };
    let minDist = 50;
    let dist = 0;
    let point = null;
    dist = distXY(x-crop.x, y-crop.y);  if (dist < minDist) { minDist = dist; point = "center" }
    dist = distXY(x-crop.left, y-crop.top); if (dist < minDist) { minDist = dist; point = "leftTop" }
    dist = distXY(x-crop.right, y-crop.top); if (dist < minDist) { minDist = dist; point = "rightTop" }
    dist = distXY(x-crop.right, y-crop.bottom); if (dist < minDist) { minDist = dist; point = "rightBottom" }
    dist = distXY(x-crop.left, y-crop.bottom); if (dist < minDist) { minDist = dist; point = "leftBottom" }
    dist = distXY(x-crop.x, y-crop.top); if (dist < minDist) { minDist = dist; point = "top" }
    dist = distXY(x-crop.x, y-crop.bottom); if (dist < minDist) { minDist = dist; point = "bottom" }
    dist = distXY(x-crop.left, y-crop.y); if (dist < minDist) { minDist = dist; point = "left" }
    dist = distXY(x-crop.right, y-crop.y); if (dist < minDist) { minDist = dist; point = "right" }
    return point;
}

function drawPoint(ctx, crop, point) {
    var path = new Path2D()
    switch(point) {
        case "center": path.arc(crop.x, crop.y, 30, 0, 2 * Math.PI); 
                       break;
        case "leftTop": path.moveTo(crop.left, crop.top + 30); path.lineTo(crop.left, crop.top); path.lineTo(crop.left + 30, crop.top); path.arc(crop.left, crop.top, 30, 0, Math.PI / 2);
                        break;
        case "rightTop": path.moveTo(crop.right - 30, crop.top); path.lineTo(crop.right, crop.top); path.lineTo(crop.right, crop.top + 30); path.arc(crop.right, crop.top, 30, Math.PI / 2, Math.PI);
                        break;
        case "rightBottom": path.moveTo(crop.right, crop.bottom - 30); path.lineTo(crop.right, crop.bottom); path.lineTo(crop.right - 30, crop.bottom); path.arc(crop.right, crop.bottom, 30, Math.PI, 3 * Math.PI / 2);
                            break;
        case "leftBottom": path.moveTo(crop.left + 30, crop.bottom); path.lineTo(crop.left, crop.bottom); path.lineTo(crop.left, crop.bottom - 30); path.arc(crop.left, crop.bottom, 30, 3 * Math.PI / 2, 2 * Math.PI);                   
                           break; 
        case "left": path.moveTo(crop.left, crop.y + 30); path.lineTo(crop.left, crop.y - 30); path.arc(crop.left, crop.y, 30, 3 * Math.PI / 2, Math.PI / 2);                   
                     break; 
        case "right": path.moveTo(crop.right, crop.y - 30); path.lineTo(crop.right, crop.y + 30); path.arc(crop.right, crop.y, 30, Math.PI / 2, 3 * Math.PI / 2);                   
                     break;                      
        case "top": path.moveTo(crop.x - 30, crop.top); path.lineTo(crop.x + 30, crop.top); path.arc(crop.x, crop.top, 30, 0, Math.PI);                   
                    break;                       
        case "bottom": path.moveTo(crop.x + 30, crop.bottom); path.lineTo(crop.x - 30, crop.bottom); path.arc(crop.x, crop.bottom, 30, Math.PI, 0);                   
                    break;                       
        default:
        }      
    ctx.globalAlpha = 0.3;
    ctx.fillStyle = "#fff";
    ctx.fill(path);
    ctx.globalAlpha = 1;
}


function draw5Points(ctx, crop0, point) {
    const crop = { ...crop0, ...cropCenter(crop0), ...cropRightBottom(crop0) };
  
    drawPoint(ctx, crop, point);
    
}

function setNewCrop(x, y, point, crop0, width, height) {
    const crop = { ...crop0, ...cropCenter(crop0), ...cropRightBottom(crop0) };
    let ratio = 1;
    switch(point) {
        case "center": crop.left = x - crop.width / 2; crop.top = y - crop.height / 2;
                       if (crop.left < 0) crop.left = 0;
                       if (crop.top < 0) crop.top = 0;
                       if (crop.left + crop.width > width) { crop.left = width - crop.width; }
                       if (crop.top + crop.height > height) { crop.top = height - crop.height; } 
                       return (crop);
        case "leftTop": ratio = distXY(x - crop.x, y - crop.y) / distXY(crop.left - crop.x, crop.top - crop.y);       
                        break;
        case "rightTop": ratio = distXY(x - crop.x, y - crop.y) / distXY(crop.right - crop.x, crop.top - crop.y);       
                         break;
        case "rightBottom": ratio = distXY(x - crop.x, y - crop.y) / distXY(crop.right - crop.x, crop.bottom - crop.y);        
                            break;
        case "leftBottom": ratio = distXY(x - crop.x, y - crop.y) / distXY(crop.left - crop.x, crop.bottom - crop.y);     
                           break;
        case "left": crop.left = x; if (crop.left > crop.right - 50) crop.left = crop.right - 50;
                     if (!crop.square) {  crop.width = crop.right - crop.left; return (crop); };
                     crop.x = (crop.left + crop.right) / 2;
                     ratio = (crop.right - crop.left) / crop.width;
                     break;
        case "right": crop.right = x; if (crop.right < crop.left + 50) crop.right = crop.left + 50; 
                      if (!crop.square) { crop.width = crop.right - crop.left; return (crop); }
                      crop.x = (crop.left + crop.right) / 2;
                      ratio = (crop.right - crop.left) / crop.width;
                      break;           
        case "top": crop.top = y; if (crop.top > crop.bottom - 50) crop.top = crop.bottom - 50; 
                    if (!crop.square) { crop.height = crop.bottom - crop.top; return (crop); }
                    ratio = (crop.bottom - crop.top) / crop.height;
                    crop.y = (crop.top + crop.bottom) / 2;
                    break;           
        case "bottom": crop.bottom = y; if (crop.bottom < crop.top + 50) crop.bottom = crop.top + 50; 
                       if (!crop.square) { crop.height = crop.bottom - crop.top; return (crop); }
                       ratio = (crop.bottom - crop.top) / crop.height;
                       crop.y = (crop.top + crop.bottom) / 2;
                       break;                                               
        default:
        }     
        crop.width = crop.width * ratio;
        crop.height = crop.height * ratio;
        if (crop.width > width) { ratio = width / crop.width; crop.width = crop.width * ratio; crop.height = crop.height * ratio; }
        if (crop.width < 50) { ratio = 50 / crop.width; crop.width = crop.width * ratio; crop.height = crop.height * ratio; }
        if (crop.height > height) { ratio = height / crop.height; crop.width = crop.width * ratio; crop.height = crop.height * ratio; }
        if (crop.height < 50) { ratio = 50 / crop.height; crop.width = crop.width * ratio; crop.height = crop.height * ratio; }
        crop.left = crop.x - crop.width / 2;
        crop.top = crop.y - crop.height / 2;
        if (crop.left < 0) crop.left = 0;
        if (crop.top < 0) crop.top = 0;
        if (crop.left + crop.width > width) { crop.left = width - crop.width; }
        if (crop.top + crop.height > height) { crop.top = height - crop.height; } 
        return (crop);
}

function drawPicture(canvas, globalState, globalDispatch, cropPoint) {
    const { navigation } = globalState;
    const { mediaCrop } = navigation;
    const { height, width } = mediaCrop;
    const imageSrc = navigation.mediaAttVal.val.attOriginalFile;
    const imageProfile = new Image();
    const ctx = canvas.getContext('2d');
    ctx.setTransform(1,0,0,1,0,0);
    ctx.scale(pop.objRel.scale, pop.objRel.scale);
    imageProfile.src = imageSrc;
    imageProfile.onload = () => { 
        let formFactor = imageProfile.naturalHeight / imageProfile.naturalWidth;
        if (height !== width * formFactor) { 
            mediaCrop.height = width * formFactor;
            globalDispatch( { type: 'update-state', state: globalState });
        }
        else { 
            ctx.drawImage(imageProfile, 0, 0, width, height); 
            if (navigation.cropPhoto) {
                fillRectInvers(ctx, mediaCrop.crop, width, height);
                draw5Points(ctx, mediaCrop.crop, cropPoint);
            }
        }
    };
}


function PopPhotoCanvas(props) {
    const { navigation } = props.globalState;
    const { mediaCrop } = navigation;
    const style = { width: "100%", height: "auto" }; 
    const canvasRef = useRef(null);
    const [cropPoint, setCropPoint] = useState(null);
    useDoubleClick({
        onSingleClick: doClick, onDoubleClick: doDoubleClick,
        ref: canvasRef,
        latency: 250
      });

    function doDoubleClick(event) { }

    function doClick(event) {
        const canvas = canvasRef.current;
        let x = event.changedTouches ? event.changedTouches[0].pageX : event.pageX;
        let y = event.changedTouches ? event.changedTouches[0].pageY : event.pageY;
        x = x - canvas.offsetLeft + canvas.parentElement.scrollLeft;
        y = y - canvas.offsetTop + canvas.parentElement.scrollTop;    
        if (cropPoint) {
            mediaCrop.crop = setNewCrop(x, y, cropPoint, mediaCrop.crop, mediaCrop.width, mediaCrop.height);
            setCropPoint(null);
            props.globalDispatch( { type: 'update-state', state: props.globalState });
        } else {
            let newPoint = findPoint(x, y, mediaCrop.crop); 
            if (newPoint) setCropPoint(newPoint);  
        }
    }



    useEffect( () => {
       if (props.parentRef.current && (mediaCrop.width !== props.parentRef.current.offsetWidth)) { 
            mediaCrop.width = props.parentRef.current.offsetWidth;
            props.globalDispatch( { type: 'update-state', state: props.globalState });
       }
       if (mediaCrop.width) { 
        drawPicture(canvasRef.current, props.globalState, props.globalDispatch, cropPoint);     
       }
    }, [props, mediaCrop, cropPoint] )     

    return (
      <canvas 
          ref={canvasRef} 
          style={style}
          width={mediaCrop.width * pop.objRel.scale}
          height={mediaCrop.height * pop.objRel.scale} />
    )

}


function PopPhotoViewCanvas(props) {
    const { navigation } = props.globalState;
    const parentRef = useRef(null);
 
   return (
        <div className = "pop-media-card">
            <div className = "pop-media-header">
                <PopSvg svg="media"/>
                <h3>{navigation.mediaName}</h3>
                <PopSvg svg={navigation.showMediaDetail ? "hide": "show"} onClick= {() => { navigation.showMediaDetail = !navigation.showMediaDetail; props.globalDispatch( { type: 'update-state', state: props.globalState });}}/>
                <PopSvg svg="close" onClick = {() => { navigation.showMedia = false; navigation.showMediaDetail = false; props.globalDispatch( { type: 'update-state', state: props.globalState });}}/>
            </div>     
            { navigation.showMediaDetail && 
            <div className = "pop-media-photo" ref = {parentRef}>
                <PopPhotoCanvas {...props} parentRef = {parentRef}  /> 
            </div>  }                
        </div>

    );    
}


function PopPhotoDetails(props) {
    const { config, navigation } = props.globalState;
    const objAttVal = navigation.mediaAttVal;    
    return (
        <div className = "pop-media-card">
            <div className = "pop-media-header">
                <PopSvg svg="info"/>
                <h3>{popCaption(config,'info').captionLong}</h3>
            </div>    
            <div className="pop-card-fields">
                { objAttVal.childAtt.map(objAtt => { return (
                <PopCardAttribute {...props} key={'Att-'+0+'-'+objAtt.att.attId} level = { 0 } objAttVal = {objAtt} editMode = {navigation.editMode && objAtt.obj.editMode} showNull = {false}/>
                ) } ) }                
            </div>
        </div> 
    )
}

function PopMedia() {
    const { globalState, globalDispatch } = useContext(Context);
    const hasDetails = globalState.navigation.showMediaDetail && (globalState.navigation.mediaAttVal.childAtt.filter(att => !att.val.nullValue).length > 0);
    return (
        <div className = "pop-media">
            <PopPhotoViewCanvas globalState = {globalState} globalDispatch = {globalDispatch}/>
            { hasDetails && <PopPhotoDetails globalState = {globalState} globalDispatch = {globalDispatch}/> }
        </div>
    );
}


export default PopMedia;
