import React, { useContext, useState } from 'react';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';
import { compare, convertObjAttValue, getShortFile, getObjAttValueToSort } from './PopFunction';
import { setHeaderColumns } from './PopObjectStruct';
import { selectObjValues } from './PopApiGlobal'
import { FILE_LOCATION } from './PopConst';



function PopSearchColumnValue(props) {
    const { column, obj } = props;
    let attValue = "";

    const objAttVal = obj.objAttValues.find(val => val.att === column);
    if (objAttVal) attValue = objAttVal.val.attValue; 
    switch(column.htmlType) {
        case "file": let shortFile = getShortFile(attValue); 
                     if (shortFile) { return (<img src={ FILE_LOCATION + shortFile} alt="profile" onClick={() => props.onClickObject(obj)}></img>) }
                     else           {  return(<PopSvg svg={obj.objType.objTypeSvg} onClick={() => props.onClickObject(obj)}/>); }
        case "select": if (!objAttVal) return (<p/>);
                       if (objAttVal.val.attValueSvg) return (<PopSvg svg = {objAttVal.val.attValueSvg} style={{"width": "25px" }}/>);              
                       if (objAttVal.val.attValueShort) return (<p onClick={ () => props.onClickSelect(obj)}>{objAttVal.val.attValueShort}</p>)   
                       return (<p onClick={ () => props.onClickSelect(obj)}>{objAttVal.val.attValue}</p>)   
        default: return (<p onClick={ () => props.onClickSelect(obj)}>{convertObjAttValue(objAttVal)}</p>)  
    }    
}

function PopSearchColumn(props) {
    const { column } = props;
    return (
        <div className="pop-result-column" style={{"flexBasis": column.flexBasis+"%" }}>
            <PopSearchColumnValue {...props}/>
        </div>
    )
}


function PopSearch() {
    const { globalState, globalDispatch } = useContext(Context);
    const [ showSort, setShowSort ] = useState(false);
    const { navigation, data, config } = globalState;

    const getAttValue = (obj, col) => { 
        let findValue = obj.objAttValues.find(val => val.att.attId === col.attId); 
        if (findValue) return getObjAttValueToSort(findValue);
        return '';
    }

    const onClickSort = (column) => {
        let ascending = 1;
        if (column.sorted) { column.sorted = false; ascending = -1; }
        else { column.sorted = true; ascending = 1; }
        data.search.objects.sort((a,b) => (compare(getAttValue(a,column),getAttValue(b,column))) ? ascending : (compare(getAttValue(b,column),getAttValue(a,column)) ? -ascending : 0));    
        globalDispatch( { type: 'update-state', state: globalState });
    }

    const filterColumn = column => {
        return ((column.objTable === 0) || (column.objTable >= data.search.columnI && column.objTable <= data.search.columnMax));
    }

    const onClickRight = () => {
        let nextColumn = data.search.header.find(column => column.objTable > data.search.columnI);
        if (nextColumn !== undefined) {
            data.search.columnI = nextColumn.objTable;
            setHeaderColumns(data.search);
            globalDispatch( { type: 'update-state', state: globalState });
        }
    }

    const onClickLeft = () => {
        let nextColumn = data.search.header.toReversed().find(column => column.objTable !== 0 && column.objTable < data.search.columnI);
        if (nextColumn !== undefined) {
            data.search.columnI = nextColumn.objTable;
            setHeaderColumns(data.search);
            globalDispatch( { type: 'update-state', state: globalState });
        }
    }


    const onClickClose = () => {
        navigation.showSearch = false; 
        navigation.showSearchDetail = false; 
        globalDispatch( { type: 'update-state', state: globalState });
    }
    
    const onClickShowHide = () => {
        navigation.showSearchDetail = !navigation.showSearchDetail; 
        globalDispatch( { type: 'update-state', state: globalState });    
    }

    const onClickObject = (obj) => {
        if (window.innerWidth < 750) navigation.showSearchDetail = false; 
        selectObjValues(obj.objTypeId, obj.objId, globalState, globalDispatch)
    }

    const onClickSelect = (obj) => {
        if (navigation.editMode) {
            if (navigation.searchObj && (navigation.searchObj !== obj)) {
                navigation.searchObj.selMode = false;
            }
            navigation.searchObj = obj;
            obj.selMode = !obj.selMode;
            globalDispatch( { type: 'update-state', state: globalState });
        }
    }

    return (
        navigation.showSearch ?
        <div className = "pop-search">
            <div className = "pop-search-header">
                <PopSvg svg="search-results"/>
                <h3>{popCaption(config,'search-results').captionLong}</h3>     
                { navigation.showSearchDetail && (data.search.columnI > data.search.column0) && <PopSvg svg="arrow-left"onClick= {onClickLeft}/> }
                { navigation.showSearchDetail && (data.search.columnMax < data.search.columnN) && <PopSvg svg="arrow-right" onClick= {onClickRight}/>} 
                { navigation.showSearchDetail && 
                <PopSvg svg="menu" onClick={()=> setShowSort(!showSort) }/> }
                <PopSvg svg={navigation.showSearchDetail ? "hide": "show"} onClick= {onClickShowHide}/>
                <PopSvg svg="close" onClick = {onClickClose}/>
            </div>                     
            { navigation.showSearchDetail && 
            <div className = "pop-search-results">
                { showSort && 
                <div className="pop-result-row-header">
                    { data.search.header.filter(filterColumn).map((column, index) => { return (
                        <div key={index} className="pop-result-column-header" style={{"flexBasis": column.flexBasis+"%" }} onClick={() => onClickSort(column)}>
                        { (column.htmlType === "file") && <PopSvg svg={data.search.objType.objTypeSvg}/>}    
                        { (column.htmlType !== "file") && <p>{column.attShort}</p> }
                        </div>
                    )})}
                </div> }                        
            { data.search.objects.map(obj => {
                return (
                < div key = {obj.objId} className="pop-result-row" style = { obj.selMode ? { background: "#e7f0ff" } : {} }>
                    { data.search.header.filter(filterColumn).map((column, index) => { return (
                        <PopSearchColumn key = {index} column = {column} obj = {obj} onClickObject={onClickObject} onClickSelect={onClickSelect}/>
                    )})}
                </div> )
            })}
            </div>}
        </div> : null
    );
}

export default PopSearch;