const PROXY = process.env.REACT_APP_PROXY;
const FILE_LOCATION = process.env.REACT_APP_SERVER + '/images/';
const SVG_LOCATION = process.env.REACT_APP_SERVER + '/svg/';

const pop = {
    objType: { person: 1, entity: 2, place: 3, event: 4, group: 5},
    userAuth: { admin: 104, user: 105, underage: 106, unverified: 121 },
    attAccess: { public: 16, friend: 1, private: 0 },
    attUse: { hidden: 0, shown: 1, mandatory: 2 },
    cardUse: { hidden: 0, shown: 1, mandatory: 2 },
    objRel: { scale: 4, profileWidth: 100, objectWidth: 50 }
  };


  

 export { pop, PROXY, FILE_LOCATION, SVG_LOCATION };