import React, { useContext } from 'react';
import Context from './Context';
import { PopSvg } from './PopSvg';
import PopMap from './PopMap';
import PopRelation from './PopRelation';
import PopMedia from './PopMedia';
import { closeObject, filterObjCardAttVal } from './PopObjectStruct';
import { PopObjectFolders } from './PopFolders';
import PopCardAttribute from './PopAttribute';
import { FILE_LOCATION } from './PopConst';


function PopObjects() {
    const { globalState } = useContext(Context);
    const { navigation, data } = globalState;

    return (
        <div className="pop-objects-map">
            { navigation.showMap && <PopMap/> }
            { navigation.showRelation && <PopRelation/> }
            { navigation.showMedia && <PopMedia/> }
            <div className={objectsClass(navigation)}>
                { data.objects.map((object, index)=> { return (
                    <PopObject object={object} key={'obj-' + object.objTypeId + '-' + object.objId} id={navigation.buildId}/>
                ) })}
            </div>  
        </div>
    )
}

function objectsClass(navigation) {
    if (!navigation.gridView) return "pop-objects";
    if (navigation.showSearchDetail && navigation.gridView) return "pop-objects-grid-grid";
    return "pop-objects-grid";
}

function objectClass(navigation) {
    if (!navigation.gridView) return "pop-object";
    if (navigation.showSearchDetail && navigation.gridView) return "pop-object-grid-grid";
    return "pop-object-grid";
}

function cardClass(navigation) {
    if (navigation.showSearchDetail && navigation.gridView) return "pop-object-cards-grid-grid";
    if (navigation.showSearchDetail && !navigation.gridView) return "pop-object-cards-main-grid";
    if (!navigation.showSearchDetail && navigation.gridView) return "pop-object-cards-object-grid";
    return "pop-object-cards";
}



function PopObjectTitle(props) {
    const { globalState, globalDispatch } = useContext(Context);

    const onClickFolders = (o) => {
        props.object.showFolders = !props.object.showFolders; 
        if (props.object.showFolders) props.object.objCards.forEach(objCard => objCard.showFolders = false);
        globalDispatch( { type: 'update-state', state: globalState });
    }
    return (
        <div className="pop-object-title" style = { { backgroundColor: "#"+ props.object.objType.objTypeColor } } >
            <div className = "pop-object-title-profile">
                { props.object.objProfile ? 
                <img src={FILE_LOCATION + props.object.objProfile} alt="profile"/> : 
                <PopSvg svg={props.object.objSvg}/> }
                <h2>{props.object.objName}</h2>
                { props.object.objHistorical && <PopSvg svg="rip" style={{ height: "20px"}} /> }
            </div>
            <div className = "pop-object-title-icons">
                { props.object.showDetail && <PopSvg svg="folder" onClick= {onClickFolders}/> }
                <PopSvg svg={props.object.showDetail ? "hide" : "show"} onClick= {o => { props.object.showDetail = !props.object.showDetail; props.object.showFolders = false; globalDispatch( { type: 'update-state', state: globalState }); }}/>
                <PopSvg svg="close" onClick= {o => { closeObject(props.object, globalState.data.objects); globalDispatch( { type: 'update-state', state: globalState }); }}/>
            </div>
        </div> 
    )

}


function PopObjectCard(props) {
    const { globalDispatch, globalState } = useContext(Context);
    return (
    <div className="pop-object-card">
        <div className="pop-object-card-header" style = { { backgroundColor: "#"+props.objCard.card.cardColor } }>
            <PopSvg svg={ props.objCard.card.cardSvg }/>
            <h3>{props.objCard.card.cardName}</h3>
            <PopSvg svg="hide" onClick={() => { props.objCard.showDetail = false; globalDispatch( { type: 'update-state', state: globalState });} }/>
        </div>
        <div className="pop-card-fields-scroll">
            <div className="pop-card-fields">
                { filterObjCardAttVal(props.objCard).map(objAttVal => { return(
                    <PopCardAttribute {...props} key={'Att-'+props.objCard.card.cardId+'-'+objAttVal.att.attId+'-'+objAttVal.val.lastUpdate} level = { 0 } objAttVal = {objAttVal}/>)})}
            </div>
        </div>
    </div>);   
}


function PopObject(props) {
    const { globalState } = useContext(Context);
    const { navigation } = globalState;
    const shownCards = props.object.objCards.filter(objCard => objCard.showDetail);

return (
        <div className={objectClass(navigation)}>
            <PopObjectTitle {...props} />
            { props.object.showFolders && 
            <PopObjectFolders {...props } keyChilds = {'folder-' + props.object.objTypeId+'-'+props.object.objId}/>  }
            { props.object.showDetail && (shownCards.length > 0) &&
            <div className={ cardClass(navigation) }>
            { shownCards.map(objCard => { return ( 
                <PopObjectCard key={'Card-'+objCard.card.cardId} objCard={objCard} editMode = {navigation.editMode && props.object.editMode} showNull = {objCard.showNull}/>
             )})}    
 
            </div> }
        </div> );
}


export default PopObjects;


