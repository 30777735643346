import React, { useContext, useEffect } from 'react';
import Context from './Context';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams  } from "react-router-dom";
import { selectSession, selectConfig, confirmVerifyEmail, confirmChangePassword } from './PopApiGlobal';

import './App.css';
import './PopGrid.css' 
import './PopHeader.css';
import './PopFooter.css';
import './PopMenu.css'; 
import './PopFolders.css'; 
import './PopLogin.css'; 
import './PopToolBox.css'; 
import './PopSearch.css'; 
import './PopObject.css'; 
import './PopAttribute.css';
import './PopMap.css';
import './PopRelation.css';
import './PopMedia.css';

import PopHeader from './PopHeader';
import PopFooter from './PopFooter';
import PopMenu from './PopMenu'; 
import PopLogin from './PopLogin';
import { PopMessage, PopChangePassword } from './PopUser';
import PopToolBox from './PopToolBox';
import PopSearch from './PopSearch';
import PopObjects from './PopObject';
import GlobalStateProvider from './GlobalStateProvider';

function App() {
  return (
    <GlobalStateProvider>
    <Router>
    <div>
      <Routes>
        <Route path="/" element={<MainPage/>}/>
        <Route path="login" element={<LoginPage/>}/>
        <Route path="verifyEmail/:langId/:verifyKey" element={<VerifyEmailPage/>}/>
        <Route path="changePassword/:langId/:verifyKey" element={<ChangePasswordPage/>}/>
        <Route path=":objLink" element={<LinkPage/>}/>
      </Routes>     
    </div>
    </Router>
  </GlobalStateProvider>
  );
}

function LoginPage() {
  const { globalState, globalDispatch } = useContext(Context);
 
  useEffect(() => { 
    if (!globalState.config.configLoaded) selectConfig(null, globalState, globalDispatch); 
    if (globalState.config.configLoaded && !globalState.user.userLogged) selectSession(globalState, globalDispatch); })
  
  return (  
    globalState.user.userLogged ? <Navigate  to="/" /> :
    globalState.config.configLoaded ?
    <div className="pop-app">
      <div className="pop-upper">
      <header>
        <PopHeader/>
        <PopMenu/> 
      </header>     
      <main className = "pop-main">
        <PopLogin/>
      </main>
      </div>
      <footer>
        <PopFooter/>
      </footer>      
    </div> :  null
     ) 
}

function VerifyEmailPage() {
  const { globalState, globalDispatch } = useContext(Context);
  const { user, config } = globalState;
  const { verifyKey, langId } = useParams();
 
  
  useEffect(() => { 
    if (!config.configLoaded) selectConfig(langId, globalState, globalDispatch); 
    if (config.configLoaded && user.emailConfirmed === null) confirmVerifyEmail(verifyKey, globalState, globalDispatch); }) 
  
  return (  
    (user.emailConfirmed !== null) && config.configLoaded ?
      <div className="pop-app">
        <div className="pop-upper">
        <header>
           <PopHeader/>
           <PopMenu/> 
        </header>     
        <main className = "pop-main">
          <PopMessage msg={user.emailConfirmed ? "confirm-email-thanks" : "invalid-link"}/> 
        </main>
        </div>
        <footer>
          <PopFooter/>
        </footer>      
      </div> : null
     ) 
}

function ChangePasswordPage() {
  const { globalState, globalDispatch } = useContext(Context);
  const { user, config } = globalState;
  const { verifyKey, langId } = useParams();
 
  
  useEffect(() => { 
    if (!config.configLoaded) selectConfig(langId, globalState, globalDispatch); 
    if (config.configLoaded && user.passwordConfirmed === null) confirmChangePassword(verifyKey, globalState, globalDispatch); }) 
  
  return (  
    (user.passwordConfirmed !== null) && config.configLoaded ?
      <div className="pop-app">
        <div className="pop-upper">
        <header>
           <PopHeader/>
           <PopMenu/> 
        </header>     
        <main className = "pop-main">
          { user.passwordConfirmed ? user.passwordChanged ? <PopMessage msg="change-pass-thanks"/> : <PopChangePassword/> : <PopMessage msg="invalid-link"/> }
        </main>
        </div>
        <footer>
          <PopFooter/>
        </footer>      
      </div> : null
     ) 
}


function LinkPage() {
  const { globalState, globalDispatch } = useContext(Context);
  const { navigation, user } = globalState;
  const { objLink } = useParams();

  useEffect(() => { 
    if (navigation.linkToLoad === null) { navigation.linkToLoad = objLink; globalDispatch( { type: 'update-state', state: globalState  }); }
    })


  return (
    !user.userLogged ? <Navigate  to="/login" /> : <Navigate  to="/" /> 
  );

}


function MainPage() {
  const { globalState } = useContext(Context);
  const { navigation, user } = globalState;

  return (
    !user.userLogged ? <Navigate  to="/login" /> :
    globalState.config.configLoaded ?
    <div className="pop-app">
      <div className="pop-upper">
      <header className="pop-header">
        <PopHeader/>
        <PopMenu/> 
      </header>    
      <main className = { navigation.showSearchDetail ? "pop-main-grid" : "pop-main" }>
        { navigation.showSearch && <PopSearch/> }
        <PopObjects/>
        { navigation.editMode && <PopToolBox/> }
      </main>  
      </div>
      <footer className="pop-footer">
        <PopFooter/>
      </footer>
    </div> : null
  );

}


export default App;
