import { FILE_LOCATION } from './PopConst';


const countSvgArg = (svg) => {
    let splitters = 'MmLlHhVvZzCcSsQqTtAa'.split("");

    let min = svg.length;
    splitters.forEach(separator => { let index = svg.slice(1).indexOf(separator); min = (index >= 0 ? index < min ? index : min : min) });
    return min;
}

const svgPathSplit = (str) => {
    let splitters = 'MmLlHhVvZzCcSsQqTtAa'.split("");


    splitters.forEach(separator => {
      str = str.replaceAll(separator, ' '+separator+' ');
    })
    str = str.replaceAll('-', ' -');
    str = str.split(" ");
    for (let i = 0; i < str.length; i++) {
        if (str[i].split('.').length > 2) {
            let pointPos = str[i].indexOf('.',str[i].indexOf('.')+1);
            str[i] = str[i].substr(0, pointPos) + ' ' + str[i].substr(pointPos);
        }
    }
    str = str.join(' ');
    return str.split(" ").filter(element => element.length > 0);
}

function nullValue(val) { return(!val.attValue && !val.attValueId && !val.objChild) };

function isNull(value) {
    return !value;
}

const validFileAddress = (url) => (url.match(/\.(jpeg|jpg|gif|png)$/) != null);

const fileExtension = (url) => url.split('.').pop();

const fileName = (objAttVal, extension = "jpeg") => 'img-' + objAttVal.obj.objTypeId + '-'+ objAttVal.obj.objId + '-'+ objAttVal.att.attId + '-'+ objAttVal.val.attInst + '.' + extension;

const fileIsUrl = (url) => (url.indexOf("http") > -1);

const getShortFile = attValue => { if (!attValue) return null; let values = attValue.split(","); return values[0] }
const getOriginalFile = attValue => { if (!attValue) return null; let values = attValue.split(","); if (values.length <= 1) return null; return (fileIsUrl(values[1]) ? '' : FILE_LOCATION) + values[1];}


function cropUrl(url) {
    if (url && url.length > 15) return url.substr(0,15) + '...';
    else return url;
}


const convertObjAttValue = ( objAttVal => {
    if (!objAttVal || objAttVal.val.nullValue) return null;
    switch (objAttVal.att.htmlType) {
        case "date": return ymdTodmy(objAttVal.val.attValue);
        case "datetime-local": return dateTimeToStr(objAttVal.val.attValue); 
        case "url": return cropUrl(objAttVal.val.attValue);
        case "select": if (objAttVal.val.objChildName) return objAttVal.val.objChildName; else if (objAttVal.val.attValueName) return objAttVal.val.attValueName; else return objAttVal.val.attValue;
        case "measure": if (objAttVal.val.attValue) return objAttVal.val.attValue + ' ' + objAttVal.val.attValueName; else return '';
        case "object": if (objAttVal.val.objChildName) return objAttVal.val.objChildName; else return objAttVal.val.attValue;
        case "password": return "·····";
        default: return objAttVal.val.attValue;
    }
});

const getObjAttValueToSort = ( objAttVal => {
    if (!objAttVal) return null;
    switch (objAttVal.att.htmlType) {
        case "file": return objAttVal.val.attInstOrd;
        case "url": return cropUrl(objAttVal.val.attValue);
        case "select": if (objAttVal.att.attValues) return objAttVal.att.attValues.find(val => val.valueId === objAttVal.val.attValueId).valueName; else return objAttVal.val.attValue;
        case "number": if (objAttVal.val.attValue) return parseFloat(objAttVal.val.attValue); else return 0;
        case "measure": if (objAttVal.val.attValue) return parseFloat(objAttVal.val.attValue); else return 0;
        case "password": return "·····";
        default: return objAttVal.val.attValue;
    }
});


const dateTimeToStr = (s => {
    if (!s) return null;
    if (s.substr(11,5) === '00:00') { return s.substr(8,2).concat('/').concat(s.substr(5,2)).concat('/').concat(s.substr(0,4)).concat(' ') };
    return s.substr(8,2).concat('/').concat(s.substr(5,2)).concat('/').concat(s.substr(0,4)).concat(' ').concat(s.substr(11,5)); 
});



const ymdTodmy = (s => {
    if (!s) return null;
    return s.substr(8,2).concat('/').concat(s.substr(5,2)).concat('/').concat(s.substr(0,4)); 
});  

const ymdTomy = (s => {
    if (!s) return null;
    return s.substr(5,2).concat('/').concat(s.substr(0,4)); 
});  

const ymdToy = (s => {
    if (!s) return null;
    return concat(s.substr(0,4)); 
});  


const fromTomy = ((dateFromYmd, dateToYmd) => {
    if (ymdTomy(dateFromYmd) == null)
        if (ymdTomy(dateToYmd) == null) return '';
        else return '- '+ymdTomy(dateToYmd);
    else 
        if (ymdTomy(dateToYmd) == null) return ymdTomy(dateFromYmd) + ' -';
        else return ymdTomy(dateFromYmd) + ' - ' + ymdTomy(dateToYmd);
});

const fromToy = ((dateFromYmd, dateToYmd) => {
    if (ymdToy(dateFromYmd) == null)
        if (ymdToy(dateToYmd) == null) return '';
        else return '- '+ymdToy(dateToYmd);
    else 
        if (ymdToy(dateToYmd) == null) return ymdToy(dateFromYmd) + ' -';
        else return ymdToy(dateFromYmd) + ' - ' + ymdToy(dateToYmd);
});



function compare(a,b) {
    if (isNull(b) && !isNull(a)) return true;
    if (isNull(a)) return false;
    if (typeof a === "string" && typeof b === "string") 
        return (a.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    else return (a > b);
}

function age(dateString) {
    if (isNull(dateString)) return "";
    var today = new Date();
    var birthDate = new Date(dateString);
    return yearsDates(birthDate, today).toString();
}

function years(dateString1, dateString2) {
    if (isNull(dateString1) || isNull(dateString2)) return "";
    var date1 = new Date(dateString1);
    var date2 = new Date(dateString2);
    return yearsDates(date1, date2).toString();
}

function year(dateString) {
    if (isNull(dateString)) return "";
    var year = new Date(dateString);
    return year.getFullYear().toString();
}


function yearsDates(date1, date2) {
    var result = date2.getFullYear() - date1.getFullYear();
    var m = date2.getMonth() - date1.getMonth();
    if (m < 0 || (m === 0 && date2.getDate() < date1.getDate())) { result--; }
    return result;
}



function concat(string1, string2, char) {
    if (!isNull(string1) && !isNull(string2)) return string1 + char + string2;
    if (!isNull(string1)) return string1;
    if (!isNull(string2)) return string2;
    return "";
}

function strDecimals(string, decimals) {
    let posComa = string.indexOf('.');
    if (posComa > -1) return string.substring(0,posComa+1+decimals);
    else return string;
}

function complex(lat, lng) {
    if (isNull(lat) && isNull(lng)) return "";
    return strDecimals(lat,2) + ", " + strDecimals(lng,2);
}

function either(string1, string2) {
    if (!isNull(string1)) return string1;
    if (!isNull(string2)) return string2;
    return "";
}


function attParamValue(objAttValue, index) {
    let value = objAttValue.obj.objAttValues.find(attVal => attVal.att.attId === objAttValue.att.attFunction.atts[index] && 
                                                            !attVal.val.nullValue && (
                                                            (attVal.parentAtt === objAttValue.parentAtt) || 
                                                            (attVal.parentAtt === objAttValue) ||
                                                            (attVal.parentAtt === null) ));
    if (value !== undefined) return value.val.attValue;
    return "";
}

function execFunction(objAttValue) {
    if (objAttValue.att.attFunction.optional && !nullValue(objAttValue.val)) return objAttValue.val.attValue;
    switch(objAttValue.att.attFunction.code) {
        case "age":
            objAttValue.val.attValue = age(attParamValue(objAttValue, 0));
            objAttValue.val.nullValue = isNull(objAttValue.val.attValue);
            if (!objAttValue.val.nullValue) {
                objAttValue.val.attValueId = objAttValue.att.attValues[0].valueId;
                objAttValue.val.attValueName = objAttValue.att.attValues[0].valueName;
                return objAttValue.val.attValue; }
            else { objAttValue.val.attValueId = null;  return objAttValue.val.attValue; }
        case "year":
            objAttValue.val.attValue = year(attParamValue(objAttValue, 0));
            objAttValue.val.nullValue = isNull(objAttValue.val.attValue);
            return objAttValue.val.attValue;    
        case "years":
            objAttValue.val.attValue = years(attParamValue(objAttValue, 0), attParamValue(objAttValue, 1));
            objAttValue.val.nullValue = isNull(objAttValue.val.attValue);
            if (!objAttValue.val.nullValue) {
                objAttValue.val.attValueId = objAttValue.att.attValues[0].valueId;
                objAttValue.val.attValueName = objAttValue.att.attValues[0].valueName;
                return objAttValue.val.attValue; }
            else { objAttValue.val.attValueId = null; return objAttValue.val.attValue; }           
        case "concat":            
            let att0 = objAttValue.att.attFunction.atts[0];
            if (",.;-=&_+".includes(att0)) objAttValue.val.attValue = concat(attParamValue(objAttValue, 1), attParamValue(objAttValue, 2), att0);
            else objAttValue.val.attValue = concat(attParamValue(objAttValue, 0), attParamValue(objAttValue, 1), " ");
            objAttValue.val.attValueId = null;
            objAttValue.val.nullValue = isNull(objAttValue.val.attValue);
            return objAttValue.val.attValue;
        case "complex":
            objAttValue.val.attValue = complex(attParamValue(objAttValue, 0), attParamValue(objAttValue, 1));
            objAttValue.val.attValueId = null;
            objAttValue.val.nullValue = isNull(objAttValue.val.attValue);
            return objAttValue.val.attValue;
        case "either":
            objAttValue.val.attValue = either(attParamValue(objAttValue, 0), attParamValue(objAttValue, 1));
            objAttValue.val.attValueId = null;
            objAttValue.val.nullValue = isNull(objAttValue.val.attValue);
            return objAttValue.val.attValue;           
        case "fromTo":
            objAttValue.val.attValue =  fromToy(attParamValue(objAttValue, 0), attParamValue(objAttValue, 1));
            objAttValue.val.attValueId = null;
            objAttValue.val.nullValue = isNull(objAttValue.val.attValue);
            return objAttValue.val.attValue;                     
        default: 
            objAttValue.val.attValue = null;   
            objAttValue.val.attValueId = null;
            objAttValue.val.nullValue = true;
            return objAttValue.val.attValue;   

    }
}

function searchValue(field, objAttVal) {
    if (field.searchValueId) return parseInt(field.searchValueId);
    if (objAttVal.att.htmlType === "decimal" || objAttVal.att.htmlType === "measure") return parseFloat(field.searchValue);
    return (field.searchValue);
}

function attValue(field, objAttVal) {
    if (field.searchValueId) return objAttVal.val.attValueId;
    if (objAttVal.att.htmlType === "decimal" || objAttVal.att.htmlType === "measure") return parseFloat(objAttVal.val.attValue);
    return (objAttVal.val.attValue);
}

function stringLike(text, match) {
    if (typeof match !== 'string' || text === null) { return false; }
    // Remove special chars
    match = match.replace(new RegExp("([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])", "g"), "\\$1");
    // Replace % and _ with equivalent regex
    match = match.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    match = match.replace(/%/g, '.*').replace(/_/g, '.');
    // Check matches
    return RegExp('^' + match + '$', 'gi').test(text.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
}

function filterObject(object, searchFields) {
    let filterALL = true;
    let filter = true;
    searchFields.forEach(field => {
        filter = true;
        let findValue = object.objAttValues.find(val => val.att.attId === field.attId);
        if (findValue === undefined || !filterALL) filter = false;
        else switch(field.check) {
            case "=":    filter = (attValue(field, findValue) === searchValue(field, findValue)); break;
            case "like": filter = (stringLike(findValue.val.attValue, "%"+field.searchValue+"%")); break;
            case ">":    filter = (attValue(field, findValue) > searchValue(field, findValue)); break;
            case "<":    filter = (attValue(field, findValue) < searchValue(field, findValue)); break;
            case ">=":   filter = (attValue(field, findValue) >= searchValue(field, findValue)); break;
            case "<=":   filter = (attValue(field, findValue) <= searchValue(field, findValue)); break;
            case "<>":   filter = (attValue(field, findValue) !== searchValue(field, findValue)); break
            default: ;
            }
        filterALL = filterALL && filter;    
        }
        
    )
    return filterALL;
}


function likeAtt(searchAtt, attributes) {
    let htmlType = searchAtt.htmlType;

    if (searchAtt.objTypeEqual) {
        let findAtt = attributes.find(att => att.objTypeId === searchAtt.objTypeEqual && att.attId === searchAtt.attEqual);
        if (findAtt !== undefined) htmlType = findAtt.htmlType;
    }
    return (htmlType === "text" || htmlType === "datalist")
}

function searchParams(objTypeId, attributes, searchValue, search) {
    let searchChecks = ">= <= <> = > <".split(" ");
    let searchAttributes = attributes.filter(att => att.objTypeId === objTypeId && att.attField && att.attShort);
    let searchAttId = null;
    let searchIsNumber = null;
    let findValue = null;
    let searchAttValue = "";
    let postFilter = false;

    
    if (searchValue.trim()[0] === "&") {
        if (objTypeId === search.objTypeId) 
            searchValue = (search.searchValue) ? search.searchValue + searchValue: searchValue; 
        else searchValue = searchValue.trim().substr(1);
    }
    search.searchValue = searchValue;
    let searchParts = searchValue.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase().split("&");
    let searchFields = searchParts.map(searchPart => {
        let searchValueId = null;
        let searchAtt = searchAttributes.find(att => searchPart.indexOf(att.attShort.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()) >= 0);
        let searchCheck = searchChecks.find(check => searchPart.indexOf(check) >= 0);
        if (searchAtt !== undefined) {
            searchAttValue = searchPart.replace(searchAtt.attShort.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase(), "").trim();
            searchAttId = searchAtt.attId;
            searchIsNumber = (searchAtt.htmlType === "decimal" || searchAtt.htmlType === "measure");
            }
        else searchAttValue = searchPart.trim();           
        if (searchCheck !== undefined) searchAttValue = searchAttValue.replace(searchCheck, "").trim();
        else searchCheck = "=";
        if (searchAtt && searchAtt.attValues) {
            findValue = searchAtt.attValues.find(val => (val.valueName.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase() === searchAttValue) || (val.valueShort.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase() === searchAttValue));
            if (findValue !== undefined) searchValueId = findValue.valueId;
        }
        postFilter = (searchAtt && (searchAtt.objTypeEqual !== null || (searchAtt.attFunction && !searchAtt.attFunction.optional))) ? true : false;   
        if (searchCheck === "=" && ((searchAtt && likeAtt(searchAtt, attributes)) || !searchAtt)) { 
            searchCheck = "like";  
            searchAttValue = (searchAttValue && searchAttValue !== "*" && searchAttValue.toUpperCase() !== "ALL") ? "%"+searchAttValue+"%" : "%"; }
            return( { attId: searchAttId, check: searchCheck, searchValue: searchAttValue, searchValueId: searchValueId, searchIsNumber: searchIsNumber, postFilter: postFilter } )});
    if (searchFields[0].attId === null) {
        let attName = searchAttributes.find(att => att.attField === "objName");
        if (attName !== undefined) { 
            searchFields[0].attId = attName.attId; 
            searchFields[0].searchIsNumber = (attName.htmlType === "decimal" || attName.htmlType === "measure"); }
        searchFields[0].postFilter = false;
    }
    return (searchFields.filter(att => att.attId));
}

const objPoi = (obj) => { 
    let coord = { objLat: null, objLng: null, objProfile: null };
    let value = "";
    value = obj.objAttValues.find(attVal => attVal.val.attField === "objLat"); if (value) coord.objLat = value.val.attValue;
    value = obj.objAttValues.find(attVal => attVal.val.attField === "objLng"); if (value) coord.objLng = value.val.attValue;
    value = obj.objAttValues.find(attVal => attVal.val.attField === "objProfile"); if (value) coord.objProfile = getShortFile(value.val.attValue);
    coord.objSvg = obj.objType.objTypeSvg;
    coord.objTypeId = obj.objTypeId;
    coord.objId = obj.objId;
    return coord;
}


const radians = (angle) => angle * Math.PI / 180;
const distance = (lat1,lng1, lat2, lng2) => 6371010 * Math.acos(Math.sin(radians(lat1))*Math.sin(radians(lat2)) + Math.cos(radians(lat1))*Math.cos(radians(lat2))*Math.cos(radians(lng2 - lng1)));
const distanceY = (lat1, lat2) => 6371010 * Math.acos(Math.sin(radians(lat1))*Math.sin(radians(lat2)) + Math.cos(radians(lat1))*Math.cos(radians(lat2)));
const distanceX = (lng1, lng2, lat) => 6371010 * Math.acos(1 + Math.cos(radians(lat))*Math.cos(radians(lat))*(Math.cos(radians(lng2 - lng1))-1));

const angle = (lng1, lng2) => {  if (lng1 - lng2 < 0) return((lng1 - lng2 + 2 * Math.PI)  % (2 * Math.PI)); else return(lng1 - lng2) };

export { compare, isNull, ymdTodmy, fromTomy, cropUrl, svgPathSplit, countSvgArg, convertObjAttValue, getObjAttValueToSort, dateTimeToStr, objPoi,
         execFunction, fileExtension, fileIsUrl, fileName, validFileAddress, getShortFile, getOriginalFile, nullValue, searchParams, filterObject, distance, distanceX, distanceY, angle };