import React, { useContext, useState } from 'react';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';
import { updateChangePassword } from './PopApiGlobal'


function PopMessage(props) {
    const { globalState } = useContext(Context);
    const { config } = globalState;
  
     const onSubmit = e => {
        e.preventDefault();
        window.opener = null;
        window.open("", "_self");
        window.close();   
    }

    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="info"/> 
                            <h3>{popCaption(config, props.msg).captionShort}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-message">
                                    <p>{popCaption(config, props.msg).captionLong}</p>
                                </div>
                                <button className="pop-login-field-icon">
                                        <PopSvg svg="leave"/>
                                </button>                        
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}

function PopChangePassword() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user } = globalState;
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');


    const onSubmit = e => {
        e.preventDefault();
        if (password === password2 && password.length >= 6)
        updateChangePassword(user.verifyKey, password, globalState, globalDispatch);
    }

    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="locked"/>
                            <h3>{popCaption(config, "change-pass-edit").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{user.objEmail}</p> 
                                </div>                        
                            </div>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password" type = "password" name = "password" value = { password } onChange = { o => setPassword(o.target.value) }/>   
                                </div>                        
                            </div> 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password2").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password2" type = "password" name = "password2" value = { password2 } onChange = { o => setPassword2(o.target.value) }/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>      
                                </div>                        
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}


export { PopMessage, PopChangePassword } ;