import Cookies from 'universal-cookie';
import { PROXY, pop } from './PopConst';
import { clearState, clearNavigation } from './PopState';
import { setToolItems, setMenuItems } from './PopActions';
import { popCaption } from './PopSvg';
import { searchParams } from './PopFunction';
import { structAttributes, addObject, updateObjectInst, pushObjChilds, updateObjChilds, structObject, structObjectHeader, structObjProfile, 
         updateAttFileValue, deleteObjChilds, structSearch, filterSearch, structMap, calcRelationMap, updateObjectDependentValues, updateObject } from './PopObjectStruct';


function getSessionCookie() {
    const cookies = new Cookies();
    return cookies.get('sessionKey');

}

function setSessionCookie(sessionKey) {  
    const cookies = new Cookies();
    
    if (sessionKey !== undefined) 
        cookies.set('sessionKey', sessionKey, { path: '/', sameSite: 'strict', expires: new Date(new Date().getTime() + 30 * 24 * 3600 * 1000) });
}

function deleteSessionCookie() {
    const cookies = new Cookies();
    return cookies.remove('sessionKey');
}


function getLangCookie() {
    const cookies = new Cookies();
    let langId = cookies.get('sessionLang');
    if (langId) return (langId);
    return ("CT");
}

function setLangCookie(langId) {  
    const cookies = new Cookies();
    
    if (langId !== undefined) 
        cookies.set('sessionLang', langId, { path: '/', sameSite: 'strict' });
}


 async function selectLogin(login, password, vCode, langId, globalState, globalDispatch) { 
    try {
        const resultIp = await selectIp();
        const fResult =  await fetch(`${PROXY}/api/login/${login}/${password}/${vCode || "null"}/${langId}/${resultIp.ipAddress}/${resultIp.latitude}/${resultIp.longitude}` );
        const result = await fResult.json();
        globalState.user.validLogin = (result[0][0].validLogin);
        globalState.user.validEmail = (result[0][0].validEmail);
        globalState.user.verifiedEmail = (result[0][0].verifiedEmail);
        if (result[0] && result[0][0] && result[0][0].sessionKey && result[1] !== undefined) {
            assignSessionResult(globalState, result);
            setSessionCookie(globalState.user.sessionKey);
            setLangCookie(globalState.navigation.langId);
            if (globalState.navigation.linkToLoad !== null && !globalState.navigation.linkLoaded) selectObjLink(globalState.navigation.linkToLoad, globalState, globalDispatch)
        }
        globalDispatch( { type: 'update-state', state: globalState });
        
    }
    catch(e) {
        console.log(e);
    }   
 }

 async function selectIp() { /* updated */
    try {
        const fResult =  await fetch(`https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1`);
        const result = await fResult.json();
        return ( { country: result.country_code, city: result.city, ipAddress: result.IPv4, latitude: result.latitude, longitude: result.longitude });
        }
        catch(e) { console.log(e); }
 }



 async function selectConfig(langId, globalState, globalDispatch) { 
    let configLang = langId || getLangCookie();
    try {
        const fResult =  await fetch(`${PROXY}/api/config/${configLang}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.navigation.langId = configLang;  
            globalState.config.langs = result[0];
            globalState.config.svgs = result[1];  
            globalState.config.captions = result[2];  
            globalState.config.configLoaded = true;
            globalDispatch( { type: 'update-state', state: globalState });
        }
    }        
    catch(e) { console.log(e); }
 }

 async function selectConfigLang(globalState, globalDispatch) { 
    if (!globalState.user.userLogged) {
        setLangCookie(globalState.navigation.langId); 
        selectConfig(globalState.navigation.langId, globalState, globalDispatch)
    }
    else {
        try {
            const fResult =  await fetch(`${PROXY}/api/configLang/${globalState.navigation.langId}` );
            const result = await fResult.json();
            if (result[0] !== undefined) {
                setLangCookie(globalState.navigation.langId);
                globalState.config.objTypes = result[0];
                globalState.config.attributes = result[1]; structAttributes(globalState.config.attributes);
                globalState.config.cards = result[2]; globalState.config.cards.filter(card => card.cardParent !== 0).forEach(card => { let parentCard = globalState.config.cards.find(parentCard => parentCard.cardId === card.cardParent); if (parentCard !== undefined) { if (parentCard.childCards === undefined) parentCard.childCards = []; parentCard.childCards.push(card); card.parentCard = parentCard; }});
                globalState.config.attValues = result[3];
                globalState.config.captions = result[4];
                globalState.config.attributes.forEach(att => { if (att.listId !== null) att.attValues = globalState.config.attValues.filter(val => val.listId === att.listId); });
                globalState.config.toolItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong);
                globalState.config.menuItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong );
                globalDispatch( { type: 'update-state', state: globalState });
            }
        }        
        catch(e) { console.log(e); }
    }    
 }



function assignSessionResult(globalState, result) {
    globalState.user = { userLogged: true, sessionKey: result[0][0].sessionKey, ...result[1] };
    globalState.navigation.langId =  globalState.user.userLangId;
    globalState.config.objTypes = result[3];
    globalState.config.attributes = result[4]; structAttributes(globalState.config.attributes);
    globalState.config.cards = result[5]; globalState.config.cards.filter(card => card.cardParent !== 0).forEach(card => { let parentCard = globalState.config.cards.find(parentCard => parentCard.cardId === card.cardParent); if (parentCard !== undefined) { if (parentCard.childCards === undefined) parentCard.childCards = []; parentCard.childCards.push(card); card.parentCard = parentCard; }});
    globalState.config.attValues = result[6];   
    globalState.config.captions = result[7];  
    globalState.config.attributes.forEach(att => { if (att.listId !== null) att.attValues = globalState.config.attValues.filter(val => val.listId === att.listId); });
    globalState.config.toolItems = setToolItems();
    globalState.config.menuItems = setMenuItems();
    globalState.config.toolItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong );
    globalState.config.menuItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong );
}

 async function selectSession(globalState, globalDispatch) {
    let sessionKey = getSessionCookie();   
    if (sessionKey === undefined) return; 
    
    try {
        const fResult =  await fetch(`${PROXY}/api/session/${sessionKey}`);
        const result = await fResult.json();
        if (result[1] !== undefined) {
            assignSessionResult(globalState, result);
            setLangCookie(globalState.navigation.langId)
            if (globalState.navigation.linkToLoad !== null && !globalState.navigation.linkLoaded) selectObjLink(globalState.navigation.linkToLoad, globalState, globalDispatch)
            globalDispatch( { type: 'update-state', state: globalState });
        }
    }
    catch(e) { console.log(e); }
}

   
async function deleteSession(globalState, globalDispatch) {  
    try {
       const fResult =  await fetch(`${PROXY}/api/session/delete/${globalState.user.sessionKey}`);
       const result = await fResult.json();
       if (result !== undefined)
           deleteSessionCookie();
           clearState(globalState);
           globalState.navigation.langId = getLangCookie();
           globalDispatch( { type: 'update-state', state: globalState });
       }
       catch(e) { console.log(e); }
}   


async function searchObjName(objName, objTypeId, globalState, globalDispatch) { 
    const { user } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObj/${objTypeId}/${objName}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       globalState.data.searchObjTypeId = objTypeId;
       globalState.data.searchResults = result[0];
       globalState.data.searchHeader = result[2];
       globalState.navigation.showMenu = false;
       globalState.navigation.showSearch = true;
       globalState.navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }


 async function searchObjAtt(objTypeId, attId, searchValueId, searchValue, globalState, globalDispatch) { 
    const { user } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObjAtt/${objTypeId}/${attId}/${searchValueId}/${searchValue}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       globalState.data.searchObjTypeId = objTypeId;
       globalState.data.searchResults = result[0];
       globalState.data.searchHeader = result[2];
       globalState.navigation.showMenu = false;
       globalState.navigation.showSearch = true;
       globalState.navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }



async function searchObjMultiple(objTypeId, attId, searchValue, globalState, globalDispatch) { 
    const { user, config, data, navigation } = globalState;

    let searchFields = searchParams(objTypeId, config.attributes, searchValue, data.search);
    let searchFieldsSQL = searchFields.filter(field => !field.postFilter);
    
    try {
        const fResult =  await fetch(`${PROXY}/api/searchObjMultiple`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify({ objTypeId:objTypeId, userId: user.userId, userAuth: user.userAuth, searchFields: searchFieldsSQL })
              });
       const result = await fResult.json();
       structSearch(objTypeId, attId, searchValue, config, [result], data.search);
       filterSearch(data.search, searchFields.filter(field => field.postFilter));
       structMap(navigation, data);
      
       navigation.showMenu = false;
       navigation.showSearch = true;
       navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }



 async function searchObjAtt2(objTypeId, attId, searchValueId, searchValue, globalState, globalDispatch) { 
    const { user, config, data, navigation } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObjAtt2/${objTypeId}/${attId}/${searchValueId}/${searchValue}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       structSearch(objTypeId, attId, searchValue, config, result, data.search);
       navigation.showMenu = false;
       navigation.showSearch = true;
       navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }



 /* searchObjChilds/:objTypeId/:objId/:attId/:userId/:userAuth */

 async function searchObjChilds(objAttVal, globalState, globalDispatch) { 
    const { user } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObjChilds/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       globalState.data.searchObjTypeId = objAttVal.obj.objTypeId;
       globalState.data.searchResults = result[0];
       globalState.data.searchHeader = result[2];
       globalState.navigation.showMenu = false;
       globalState.navigation.showSearch = true;
       globalState.navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }


 async function selectObjValues(objTypeId, objId, globalState, globalDispatch) {
    const { user, navigation, data } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objValues/${objTypeId}/${objId}/${user.userId}/${user.userAuth}` );
        const result = await fResult.json();
        let objValues = result[0];
        if (objValues.length > 0) {
            clearNavigation(globalState);
            let object = addObject(objTypeId, objId, result[1][0].objRelated, (result[2][0].userIsAdmin !== 0), objValues, data.objects, navigation.maxObjects);
            structObject(object, globalState);
            window.scrollTo(0, 0);  
            globalDispatch( { type: 'update-state', state: globalState  });
        }
    }
    catch(e) {
        console.log(e);
    }
  
}

async function selectObjLink(objLink, globalState, globalDispatch) {
    const { user, navigation, data } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objLink/${objLink}/${user.userId}/${user.userAuth}` );
        const result = await fResult.json();
        let objValues = result[0];
        if (objValues.length > 0) {
            clearNavigation(globalState);
            let object = addObject(objValues[0].objTypeId, objValues[0].objId, result[1][0].objRelated, (result[2][0].userIsAdmin !== 0), objValues, data.objects, navigation.maxObjects);
            structObject(object, globalState);
            window.scrollTo(0, 0);  
        }
        navigation.linkLoaded = true;
        globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
        console.log(e);
    }
  
}

async function selectObjRelated(globalState, globalDispatch) {
    const { user,  data } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objRelated/${user.userId}` );
        const result = await fResult.json();
        data.objRelated = result[0];
        calcRelationMap(globalState);
        user.objRelatedLoaded = true;
        globalDispatch( { type: 'update-state', state: globalState });
    }
    catch(e) {
        console.log(e);
    }
  
}

async function selectObjChilds(objAttVal, globalState, globalDispatch) {
    const { user } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objChilds/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}/${objAttVal.val.objChildTypeId}/${objAttVal.val.objChild}/${user.userId}/${user.userAuth}` );
        const result = await fResult.json();
        let objValues = result[0];
        if (objValues.length > 0) {
           updateObjectInst(objAttVal.obj.objAttValues, objValues); 
           updateObject(objAttVal.obj, objValues);
        }
        globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
        console.log(e);
    }
  
}


 async function updateServer() { 
    console.log("update server");
    try {
      const fResult =  await fetch(`${PROXY}/api/updateServer`);
      const result = await fResult.json();
      console.log("update result", result);
    }
    catch(e) { console.log(e); }
}


function objValueAssignParent(objAttVal, objAttValues) {   
    if (objAttVal.nav.isHeader) return;
    if (objAttValues.indexOf(objAttVal) === -1) objAttValues.push(objAttVal);
    if ((objAttVal.val.attInst === 0) && (objAttVal.parentAtt !== null) && (objAttVal.parentAtt.val.attInst === 0)) 
        objValueAssignParent(objAttVal.parentAtt, objAttValues);
}


async function updateNewObject(object, res, err) {
    let objAttValues = object.objAttValues.filter(att => att.val.changed);
    let objValues = objAttValues.map(objAtt => { 
        objAtt.val.changed = false;
        let objValue = { ...objAtt.val, objId: object.objId }; 
        if (objAtt.att.htmlType === 'select') objValue.attValue = null; 
        return (objValue)});
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attValues`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();
         if (result.length > 0) updateObjectInst(objAttValues, result);
         res("ok");
         }
     catch(e) {
    console.log(e);
    err(e);
     }     
}


function updateObjChildValue(objAttVal, globalState, globalDispatch) {
    const { user } = globalState;  
    
    deleteObjChilds(objAttVal);
    let promiseDelAtt = new Promise((res, err) => deleteAttValues(objAttVal, res, err)); 
    promiseDelAtt.then(res => {
        let promiseUpdateAttObjChild = new Promise((res, err) => updateAttObjChild(objAttVal, user, res, err)); 
            promiseUpdateAttObjChild.then(res => { 
                globalDispatch( { type: 'update-state', state: globalState  });
            })
    })
}

async function updateObjValues(objAttVal, globalState, globalDispatch) {        
    updateObjectDependentValues(objAttVal);
    structObjectHeader(objAttVal.obj);
    let objAttValues = objAttVal.obj.objAttValues.filter(att => att.val.changed);
    objValueAssignParent(objAttVal, objAttValues);
    let objValues = objAttValues.map(objAtt => { 
        objAtt.val.changed = false;
        let objValue = { ...objAtt.val, objId: objAttVal.obj.objId }; 
        if (objAtt.att.htmlType === 'select') objValue.attValue = null; 
        return (objValue)});
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attValues`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();
         if (result.length > 0) updateObjectInst(objAttValues, result);
         globalDispatch( { type: 'update-state', state: globalState  });   
         
         }
     catch(e) {
    console.log(e);
     }
 } 

 async function updateAttObjChild(objAttVal, user, res, err) {    
    let objAttValues = [];
    let objValues = [{...objAttVal.val, objId: objAttVal.obj.objId, userId: user.userId, userAuth: user.userAuth }];  
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attObjChild`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();   
         if (result.length > 0) {
            pushObjChilds(objAttVal, objAttValues);
            updateObjChilds(objAttValues, result);
         }
         res("ok")
        }
     catch(e) {
    console.log(e);
    err(e);
     }
 } 



 async function updateAttInstOrd(objAttVal, globalState, globalDispatch) {   
    let objValues = objAttVal.parentAtt.childAtt.filter(att => att.val.changed).map(objAtt => { objAtt.val.changed = false; return({ ...objAtt.val, objId: objAttVal.obj.objId })});
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attInstOrd`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         await fResult.json();
         globalDispatch( { type: 'update-state', state: globalState  });           
         }
     catch(e) {
    console.log(e);
     }
 } 


async function updateAttAccess(objAttVal, globalState, globalDispatch) {   
    
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attAccess/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}/${objAttVal.val.attAccess}` );
        await fResult.json();
        globalDispatch( { type: 'update-state', state: globalState  });   
        }
    catch(e) {
   console.log(e);
    }     
}

async function updateAttRelated(objAttVal, globalState, globalDispatch) {   
    
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attRelated/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}/${objAttVal.val.attRelated}` );
        await fResult.json();
        globalDispatch( { type: 'update-state', state: globalState  });   
        }
    catch(e) {
   console.log(e);
    }     
}


async function deleteAttValue(objAttVal) {   
    try {
        const fAttValue =  await fetch(`${PROXY}/api/delete/attValue/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}` );
        const attValue = await fAttValue.json();
        console.log(attValue);
        }
    catch(e) {
        console.log(e);
    }    
}


async function deleteObject(objAttVal, globalState, globalDispatch) {   
    try {
        const fAttValue =  await fetch(`${PROXY}/api/delete/obj/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${globalState.user.userId}/${globalState.user.userAuth}` );
        const attValue = await fAttValue.json();
        clearNavigation(globalState);
        globalDispatch( { type: 'update-state', state: globalState  });   
        console.log(attValue);
        }
    catch(e) {
        console.log(e);
    }    
}

/* 

/api/delete/obj/:objTypeId/:objId/:userId/:userAuth
*/


async function deleteAttValues(objAttVal, res, err) {  
    let objValues = objAttVal.obj.objAttValues.filter(att => att.val.delete && (att.val.attInst !== 0)).map(objAtt => { 
        return({ ...objAtt.val, htmlType: objAtt.val.objChild ? "null" : objAtt.att.htmlType, objId: objAttVal.obj.objId })});
    if (objValues.length === 0) { 
        res("ok");
        return; }
    try {
            const fResult =  await fetch(`${PROXY}/api/delete/attValues`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                    },
                    method: 'POST',
                    body: JSON.stringify(objValues)
                });
            await fResult.json();
            res("ok");
            }
        catch(e) {
        console.log(e);
        err(e);
        }
   
}


function updateAttOrder() {};

async function updateFileCrop(globalState, globalDispatch) {
    const { mediaCrop, mediaAttVal } = globalState.navigation;
    const crop = { left: mediaCrop.crop.left / mediaCrop.width, top: mediaCrop.crop.top / mediaCrop.height, width: mediaCrop.crop.width / mediaCrop.width, height: mediaCrop.crop.height / mediaCrop.height };
    const objValue = { ...mediaAttVal.val, ...crop,  };
    try {
        const fResult = await fetch(`${PROXY}/api/update/fileCrop`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
             method: 'POST',
             body: JSON.stringify(objValue),
           });
           await fResult.json();
           let now = new Date();       
           mediaAttVal.val.lastUpdate = now.toISOString();
           globalDispatch( { type: 'update-state', state: globalState });       
     }
     catch (e) {
         console.log('hi ha un error', e);
     }


}





async function uploadAttFile(objAttVal, uploadedFile, globalState, globalDispatch) {
    const file = new FormData();
    
    file.append('image', uploadedFile);
    file.append( 'att', JSON.stringify({...objAttVal.val, objId: objAttVal.obj.objId, attClassName: objAttVal.att.attClassName }) );
    /* let dateTime = EXIF.getTag(uploadedFile, "DateTimeOriginal"); */

    try {
       const fResult = await fetch(`${PROXY}/api/uploadFile`, {
            method: 'POST',
            body: file,
          });
          const result = await fResult.json();
          if (result !== undefined) {          
            objAttVal.val.attValue = result.attValue;
            objAttVal.obj.objId = result.objId;
            objAttVal.val.attInst = result.attInst;
            objAttVal.val.lastUpdate = result.lastUpdate;
            objAttVal.val.nullValue = false;
            if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = result.attInst);   
/* 
 if (result.length > 0) updateObjectInst(objAttValues, result);
                objAttVal.parentAtt.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = objValue.attParentInst);
            if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = objValue.attInst);  
*/
            updateAttFileValue(objAttVal);
            if (objAttVal.att.attId === objAttVal.obj.objType.attIdProfile) structObjProfile(objAttVal.obj);      
          }
          globalDispatch( { type: 'update-state', state: globalState  });   
    }
    catch (e) {
        console.log('hi ha un error', e);
    }
      
  }


  async function uploadAttFileLink(objAttVal, globalState, globalDispatch) {
    objAttVal.val.changed = false;
    let objValues = [{ ...objAttVal.val, objId: objAttVal.obj.objId, attClassName: objAttVal.att.attClassName }]; 
    
    try {
        const fResult =  await fetch(`${PROXY}/api/uploadFileLink`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();
         if (result !== undefined) {          
            objAttVal.val.attValue = result.attValue;

            objAttVal.obj.objId = result.objId;
            objAttVal.val.attInst = result.attInst;
            objAttVal.val.attValue = result.attValue;
            objAttVal.val.lastUpdate = result.lastUpdate;
            objAttVal.val.nullValue = false;
            /*
            updateObjectInst(objAttValues, objValues)
            */
            if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = result.attInst);   
            updateAttFileValue(objAttVal);
            if (objAttVal.att.attId === objAttVal.obj.objType.attIdProfile) structObjProfile(objAttVal.obj);   
         }
         globalDispatch( { type: 'update-state', state: globalState  });   
         
         }
     catch(e) {
    console.log(e);
     }
  }
/*
 { props.objAttVal.att.attValues.map(opt => {
        return <option key={'val'+opt.valueId} value={opt.valueId}>{opt.valueName}</option> } ) }
*/

  async function selectListValuesObj(objAttVal, globalState, globalDispatch) {   
    try {
        const fResult =  await fetch(`${PROXY}/api/listValuesObj/${pop.objType.person}/${globalState.user.userId}/${objAttVal.att.objChildTypeId}/${objAttVal.att.attEqual}` );
        const result = await fResult.json();
        if (result && result.length > 0)
        {
            objAttVal.val.attValues = result.map(val => { return({ valueId: val.objId, valueName: val.attValue })});
            globalDispatch( { type: 'update-state', state: globalState  });   
        }
        }
    catch(e) {
   console.log(e);
    }     
}


async function requestVerifyEmail(globalState, globalDispatch) {
    const { navigation } = globalState;
    const { objAttVal } = navigation;

    try {
        const fResult =  await fetch(`${PROXY}/api/request/email/${navigation.langId}/${objAttVal.val.attValue}` );
        await fResult.json();
        objAttVal.nav.selMode = false;
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
    catch(e) {
   console.log(e);
    }    
}


async function confirmVerifyEmail(verifyKey, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/confirm/email/${verifyKey}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.user.emailConfirmed = result[0][0].emailConfirmed;           
            globalDispatch( { type: 'update-state', state: globalState });          
            }
        }
    catch(e) {
        console.log(e);
    }    
}

async function requestChangePassword(langId, login, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/request/password/${langId}/${login}` );
        const result = await fResult.json();
        globalState.user.validEmail = null;
        globalState.user.validLogin = null;
        if (result[0] !== undefined) globalState.user.resetPassword = true;
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
    catch(e) {
        console.log(e);
    }    
}


async function confirmChangePassword(verifyKey, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/confirm/password/${verifyKey}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.user.passwordConfirmed = (result[0][0].passwordConfirmed === 1);           
            globalState.user.objEmail = result[0][0].objEmail; 
            globalState.user.verifyKey = verifyKey; 
            globalDispatch( { type: 'update-state', state: globalState });          
            }
        }
    catch(e) {
        console.log(e);
    }    
}

async function updateChangePassword(verifyKey, password, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/update/password/${verifyKey}/${password}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.user.passwordChanged = (result[0][0].passwordChanged === 1);           
            globalDispatch( { type: 'update-state', state: globalState });          
            }
        }
    catch(e) {
        console.log(e);
    }    
}


export { setSessionCookie, selectIp, selectConfig, selectConfigLang, selectLogin, selectSession, deleteSession, searchObjName, searchObjAtt, searchObjAtt2, 
        searchObjMultiple, searchObjChilds, selectObjValues, selectObjLink, selectObjChilds, updateServer, updateObjValues, updateAttObjChild, updateNewObject, 
        updateAttAccess, updateAttRelated, updateAttOrder, updateAttInstOrd, deleteAttValues, deleteAttValue, deleteObject, uploadAttFile, uploadAttFileLink, updateFileCrop, 
        selectListValuesObj, selectObjRelated, updateObjChildValue, requestVerifyEmail, confirmVerifyEmail, requestChangePassword, confirmChangePassword, updateChangePassword }