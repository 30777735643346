const initState = { 
    user : { userLogged: false, linkLoaded: false, linkToLoad: null,  emailConfirmed: null, passwordConfirmed: null, objRelatedLoaded: false },
    config: { configLoaded: false, menuItems: [], toolItems: [] },
    navigation: { 
      langId: 'CT', 
      editMode: false,
      showCrop: false,
      cropPhoto: false,
      showMenu: false,
      showFolders: false,
      showNull: false,
      gridView: false,
      showSearch: false,
      showSearchDetail: false,
      searchObjType: 0,
      showMedia: false,
      showMediaDetail: false,
      showMap: false,
      showMapDetail: false,
      showRelation: false,
      showRelationDetail: false,
      mapRelation: { x: 0, y:0, width: 0, height: 0 },
      mediaCrop: { crop: { left: null, top: null, width: null, height: null, square: true }, width: null, height: null },
      mediaName: null,
      mapCenter: { lat: 41.39, lng: 2.186 },
      mapPoi: null,
      mapPoiNew: null,
      mapPois: [],
      mapZoom: 13,
      mapBounds: {},
      objAttVal: null,
      mediaAttVal: null,
      object: null,
      maxObjects: 3,
      linkLoaded: false, 
      linkToLoad: null,
      buildId: process.env.REACT_APP_ID  
    },
    data: { objects: [], objRelated: [], search: {}, mapPois: [] },
    };
  
  
function clearState(globalState)
{
  globalState.user = { userLogged: false, linkLoaded: false, linkToLoad: null, keyConfirmed: null, emailConfirmed: null, passwordConfirmed: null, objRelatedLoaded: false };
  globalState.navigation = { 
    langId: 'CT', 
    editMode: false,
    cropPhoto: false,
    showMenu: false, 
    showFolders: false,
    showNull: false,
    gridView: false,
    showSearch: false,
    showSearchDetail: false,
    searchObjType: 0,
    showMedia: false,
    showMediaDetail: false,    
    showRelation: false,
    showRelationDetail: false,    
    mapRelation: { x: 0, y:0, width: 0, height: 0 },
    mediaCrop: { crop: { left: null, top: null, width: null, height: null, square: true }, width: null, height: null },
    mediaName: null,
    showMap: false,
    showMapDetail: false,
    mapCenter: { lat: 41.39, lng: 2.186 },
    mapUpdate: null,
    mapPoi: null,
    mapPoiNew: null,
    mapZoom: 13,
    mapBounds: {},
    objAttVal: null,
    mediaAttVal: null,
    object: null,
    maxObjects: 3,
    linkLoaded: false, 
    linkToLoad: null,
    buildId: process.env.REACT_APP_ID            
  };
  globalState.data = { objects: [], objRelated: [],  search: {}, mapPois: [] };
}

  function clearNavigation(globalState) {
    const { navigation } = globalState;

    navigation.showMedia = false;
    navigation.showMapDetail = false;
    navigation.showRelationDetail = false;
    if  (navigation.objAttVal) {
      globalState.navigation.objAttVal.nav.editMode = false;
      globalState.navigation.objAttVal.nav.selMode = false;
      globalState.navigation.objAttVal.nav.accessMode = false;
      globalState.navigation.objAttVal.nav.accessEdit = false;
      globalState.navigation.objAttVal.nav.relatedMode = false; 
      globalState.navigation.objAttVal.nav.relatedEdit = false;  
    }
    if (navigation.searchObj) navigation.searchObj.selMode = false;    
  }


  function updateState(props) {
    props.globalDispatch( { type: 'update-state', state: props.globalState });
  }

  export { initState, clearState, clearNavigation, updateState };
  
