import React from 'react';


function PopFooter() {
    return (
        <div className = "pop-footer-box">
            <div className="pop-footer-title">
                <h4>Populae Beta - Running SR Objects 2023</h4>
            </div>
        </div>
    );
}

export default PopFooter;