import React, { Fragment, useContext, useState } from 'react';
import Context from './Context';
/* import QRCode from "react-qr-code"; */
import { selectObjValues, updateObjValues, updateAttAccess, updateAttRelated, uploadAttFile, uploadAttFileLink, updateObjChildValue, selectListValuesObj } from './PopApiGlobal';
import { PopSvg } from './PopSvg'
import { pop, FILE_LOCATION } from './PopConst';
import { isNull, cropUrl, convertObjAttValue, getObjAttValueToSort, compare } from './PopFunction';
import { cloneObjAttVal, valueChanged } from './PopObjectStruct';
import { updateState } from './PopState';


/* attribute acctions */

function editOnClick(objAttVal, globalState, globalDispatch) {
    const { navigation } = globalState;

    if (objAttVal !== navigation.objAttVal && navigation.objAttVal !== null) {
        navigation.objAttVal.nav.editMode = false;
        navigation.objAttVal.nav.selMode = false;
        navigation.objAttVal.nav.accessMode = false;
        navigation.objAttVal.nav.relatedMode = false;
    }
    navigation.objAttVal = objAttVal;
    objAttVal.nav.editMode = !objAttVal.nav.editMode;
    objAttVal.nav.selMode = false;
    objAttVal.nav.accessMode = false;
    objAttVal.nav.relatedMode = false;

    if (objAttVal.att.htmlType === "select" && objAttVal.att.objChildTypeId && !objAttVal.val.attValues) {
        selectListValuesObj(objAttVal, globalState, globalDispatch)
    }
    else globalDispatch( { type: 'update-state', state: globalState });        
}

function selectOnClick(objAttVal, globalState, globalDispatch) {
    const { navigation } = globalState;
    if (objAttVal !== navigation.objAttVal && navigation.objAttVal !== null) {
        navigation.objAttVal.nav.editMode = false;
        navigation.objAttVal.nav.selMode = false;
        navigation.objAttVal.nav.accessMode = false;
        navigation.objAttVal.nav.relatedMode = false;
    }
    navigation.objAttVal = objAttVal;
    objAttVal.nav.editMode = false;
    objAttVal.nav.accessMode = false;
    objAttVal.nav.relatedMode = false;
    objAttVal.nav.selMode = !objAttVal.nav.selMode;  

    globalDispatch( { type: 'update-state', state: globalState });        
}

function attTableOnClick(objAttVal, globalState, globalDispatch) {
    if (objAttVal.nav.isHeader) {
        let findAtt = objAttVal.att.childAtt.find(att => att.attTable === (objAttVal.nav.attTableSel+1));
        if (findAtt !== undefined) objAttVal.nav.attTableSel = objAttVal.nav.attTableSel + 1;
        else objAttVal.nav.attTableSel = 1;
        globalDispatch( { type: 'update-state', state: globalState });        
    }
}

function attPhotoOnClick(objAttVal, globalState, globalDispatch) {
    const { navigation } = globalState;
    if (objAttVal !== navigation.objAttVal && navigation.objAttVal !== null) {
        navigation.objAttVal.nav.editMode = false;
        navigation.objAttVal.nav.selMode = false;
    }
    if (!isNull(objAttVal.val.objChild)) {
        selectObjValues(objAttVal.val.objChildTypeId, objAttVal.val.objChild, globalState, globalDispatch)
    }
    else { 

        if (objAttVal.nav.isHeader) navigation.mediaAttVal = cloneObjAttVal(objAttVal.selAtt);
            else navigation.mediaAttVal = cloneObjAttVal(objAttVal);
        navigation.objAttVal = navigation.mediaAttVal;
        navigation.showMedia = true;
        navigation.showMediaDetail = true;
        navigation.mediaName = (objAttVal.parentAtt === null ? objAttVal.att.attName : objAttVal.parentAtt.att.attName) + ' - ' + objAttVal.obj.objName;
        window.scrollTo(0, 0);  
        globalDispatch( { type: 'update-state', state: globalState });
    }
}

function locationOnClick(objAttVal, globalState, globalDispatch) {
    const { navigation } = globalState;
    navigation.showMap = true;
    navigation.showMapDetail = true;    
    navigation.mapCenter =  { lat: parseFloat(objAttVal.childAtt[0].val.attValue), lng: parseFloat(objAttVal.childAtt[1].val.attValue) };
    navigation.mapPoi = { ...navigation.mapCenter }; 
    navigation.mapPoiNew = (navigation.mapPoi.lat !== objAttVal.obj.objLat) || (navigation.mapPoi.lng !== objAttVal.obj.objLng);
    window.scrollTo(0, 0);  
    globalDispatch( { type: 'update-state', state: globalState });
}

function locationUse(objAttVal) {
    return (objAttVal.childAtt[0] !== undefined) && (objAttVal.childAtt[1] !== undefined) && (!objAttVal.childAtt[0].val.nullValue) && (!objAttVal.childAtt[1].val.nullValue);
}


function getHtmlType(htmlType) {
    switch (htmlType) {
        case "decimal": return "number";
        case "file": return "url";
        default: return htmlType;
    }
}

function getHtmlStep(htmlType) {
    switch (htmlType) {
        case "decimal": return "any";
        case "number": return "1";
        default: return null;
    }
}

      
function attHtmlId(objAttVal) {
    return 'att_' + objAttVal.att.attId + '_' + objAttVal.val.attInst;
}

function initAtt(objAttVal) {
    const { att, val } = objAttVal;
    if (att.htmlType === "datalist") {
        let options = [...new Set(objAttVal.obj.objAttValues.filter(objVal => objVal.att === att && !objVal.nav.isHeader && !objVal.val.nullValue).map(objVal => { return(objVal.val.attValue)}).sort((a,b) => a >= b ? 1: -1))];
        return ({ value: val.attValue, valueId: val.attValueId, checked: (val.attActive === true),
                  inputType: options.length > 0 ? "datalist" : "input",
                  options: options }); 
    }
    else return ({ value: val.attValue, valueId: val.attValueId, checked: (val.attActive === true),
                   inputType:  att.htmlType === "select" ? "select" : "input"}); 
}


function PopAttSpace(props) {
    
    let selected = (props.objAttVal.val.attActive) && (!props.objAttVal.nav.isHeader);
    let isItem = props.objAttVal.nav.isItem;
    let emptyFill = (props.objAttVal.nav.selMode || props.objAttVal.nav.editMode) ? "#e7f0ff" : "#fff";
    
    return (
        <div className = "pop-field-spaces">{[...Array(props.level)].map((e, i) => { return (
            <div className = "pop-field-space" key={i}>
                { ((i < props.level - 1) || !isItem ) &&
                <PopSvg svg= 'empty' fill={emptyFill} /> }                   
                { ((i === props.level - 1) && isItem) &&
                <PopSvg svg= {selected ? 'tick-full' : 'empty' } fill={selected ? "#86bbd8" : emptyFill} /> }
            </div> ) })}
        </div> )
}

function PopAttLabel(props) {
    const showLabel = !props.objAttVal.nav.isItem || (props.objAttVal.nav.isItem && props.objAttVal.parentAtt.singleAtt);
    return (
        <div className="pop-field-label">
            { showLabel ? 
            <label htmlFor = {attHtmlId(props.objAttVal)} onClick = {props.onClick}>{props.objAttVal.att.attName}</label> :
            <div className = "pop-field-label-space" onClick = {props.onClick}/> }
        </div> )
}


function onClickShowChilds(props) {
    props.objAttVal.nav.showChilds = !props.objAttVal.nav.showChilds;
    if (props.objAttVal.nav.isItem) {
        props.objAttVal.parentAtt.singleAtt = props.objAttVal.nav.showChilds ? props.objAttVal : null;
    }
    props.globalDispatch( { type: 'update-state', state: props.globalState });
}

function PopAttShowChilds(props) {
    return (
        <div className="pop-field-icon">
                { (props.objAttVal.childAtt.length > 0) && 
                <PopSvg svg={props.objAttVal.nav.showChilds ? "hide" : "show"} fill = {"#86bbd8"}                
                 onClick={() => onClickShowChilds(props) } /> }
        </div>     
    )
}

function PopAttTableClick(props) {
    return (
        <div className="pop-field-icon-table">
                <PopSvg svg="arrow-right" fill = "#86bbd8"                
                 onClick={() => attTableOnClick(props.objAttVal, props.globalState, props.globalDispatch)}/> 
        </div>
    )
}

function PopAttChildObject(props) {
    const onClickChild = () => { selectObjValues(props.objAttVal.val.objChildTypeId, props.objAttVal.val.objChild, props.globalState, props.globalDispatch) }

    if (!props.objAttVal.val.nullValue && !isNull(props.objAttVal.val.objChild) && (!props.objAttVal.nav.isHeader || !props.objAttVal.nav.showChilds)) {
        return (
            props.objAttVal.val.objChildProfile ? 
            <img className = "pop-field-value-large-icon" src={ FILE_LOCATION + props.objAttVal.val.objChildProfile + "?"+ props.objAttVal.val.lastUpdate} onClick={onClickChild} alt="profile"/> :
            <PopSvg svg={props.objAttVal.val.objChildSvg} className="pop-field-value-svg" fill="#86bbd8" onClick={onClickChild}/>
            )         
    }
    else return (null);
}

function PopAttValueSvg(props) {
    if (!props.objAttVal.val.nullValue && !isNull(props.objAttVal.val.attValueSvg) && (!props.objAttVal.nav.isHeader || !props.objAttVal.nav.showChilds)) 
        return (<PopSvg className = "pop-field-svg" svg = {props.objAttVal.val.attValueSvg}/>);           
    else return (null);
}

function PopAttVerified(props) {
    if (!props.objAttVal.val.nullValue && props.objAttVal.val.attVerified && (!props.objAttVal.nav.isHeader || !props.objAttVal.nav.showChilds)) 
         return (<PopSvg className = "pop-field-svg" svg = "verified"/>);           
    else return (<PopSvg className = "pop-field-svg" svg = 'empty' style={ { fillOpacity: 0 } }/>);
}

function showValue(props) {
  /*  if (props.objAttVal.val.nullValue && props.objAttVal.att.htmlType !== 'file') return false; */
    if (props.objAttVal.nav.showChilds && props.objAttVal.nav.isHeader) return false;
    if (props.objAttVal.nav.isItem && (props.objAttVal.att.attUseLabel === 0)) return false;
    return true;
}

function PopAttFileValueNull(props) {
    return (
        <div className = { "pop-field-image-new" }>      
        { (props.editMode) ? 
        <Fragment>
            <input id = {attHtmlId(props.objAttVal)} type = {props.objAttVal.att.htmlType} autoFocus onChange={props.onChange} /> 
            <label htmlFor = {attHtmlId(props.objAttVal)}>
                <PopSvg svg="photo" fill="#86bbd8"/>
            </label> 
            <PopSvg svg="bind" fill="#86bbd8" onClick={props.onClickEdit}/>
        </Fragment> :    
            <PopSvg svg="photo" fill="#86bbd8"/> }
         </div>
    )
}

function PopAttFileValue(props) {
    const isItem = props.objAttVal.nav.isItem && !props.objAttVal.nav.showChilds; 
    return (
        <Fragment>
         { (props.objAttVal.val.nullValue) ?
            <PopAttFileValueNull { ...props }/> :            
            <div className = { "pop-field-image-" + props.objAttVal.att.attClassName } >    
                <img style = { isItem ? { height: "50px" } : {} } src= {FILE_LOCATION + props.objAttVal.val.attShortFile + "?"+ props.objAttVal.val.lastUpdate} loading="lazy" onClick = {() => { attPhotoOnClick(props.objAttVal, props.globalState, props.globalDispatch) }} alt="profile"/> 
            </div> }          
        </Fragment>
    )
}

function PopAttUrlValue(props) {
    return (
        <div className="pop-field-value">
            { props.editMode &&
            <p onClick = {props.onClickEdit}>{convertObjAttValue(props.objAttVal)  || '\u00A0'}</p> }
            { !props.editMode &&
            <Fragment>
                <p><a href={props.objAttVal.val.attValue} target="_blank" rel="noopener noreferrer">{cropUrl(props.objAttVal.val.attValue)}</a></p>  
                <PopSvg className = "pop-field-svg" svg = "copy" onClick = {() => {navigator.clipboard.writeText(props.objAttVal.val.attValue)}}/>
            </Fragment>}
        </div>

    )
}



function attAccessChange(objAttVal) {
    switch (objAttVal.val.attAccess) {
        case pop.attAccess.public: objAttVal.val.attAccess = pop.attAccess.private; break;
        case pop.attAccess.private: objAttVal.val.attAccess = objAttVal.att.attAccess; break;
        default: objAttVal.val.attAccess = pop.attAccess.public;
    }    
    return (objAttVal.val.attAccess);
}

function lockIcon(attAccess) {
    switch (attAccess) {
        case pop.attAccess.public: return ('lock-open');
        case pop.attAccess.private: return ('locked');   
        default: return ('locked-friends');
    }
}


function PopAttRelated(props) {
    const { val, nav, att } = props.objAttVal;
    const [value, setValue] = useState(val.attRelated);


    function onClick() {
        nav.relatedEdit = false;
        if (value !== props.objAttVal.att.attRelated) {
            val.attRelated = att.attRelated;
            setValue(props.objAttVal.val.attRelated);
            updateAttAccess(props.objAttVal, props.globalState, props.globalDispatch); 
        }
    }         
    
    function onSubmit(o) {
        o.preventDefault();
        nav.relatedEdit = false;
        if (value === val.attRelated) updateState(props);
        else { val.attRelated = value; updateAttRelated(props.objAttVal, props.globalState, props.globalDispatch); }
    }

    return(
        <Fragment>
            <div className = "pop-field-icon">
                <PopSvg svg="relation" fill="#86bbd8" onClick={onClick} />           
            </div>    
            { !nav.relatedEdit && <PopViewAccess value={value} onClick={() => { nav.relatedEdit = true; updateState(props);}}/> }
            {  nav.relatedEdit && <PopEditAccess value={value} onSubmit={onSubmit} setValue={setValue}/> }
        </Fragment>  )
}





function PopViewAccess(props) {
    return (
    <div className = "pop-field-access" onClick={props.onClick}>
        <p>{props.value}</p>
    </div> )
}

function PopEditAccess(props) {    
    return (
    <form className = "pop-field-access" onSubmit={props.onSubmit}>
            <input type = "number" step = {1} min={0} max={pop.attAccess.public} name = "attAccess" value = {props.value} autoFocus onChange={o => props.setValue(parseInt(o.target.value))} /> 
            <button className="pop-field-icon">
                    <PopSvg svg="save" fill="#86bbd8"/>
            </button>    
    </form> )
}

function PopAttAccess(props) {
    const { val, nav } = props.objAttVal;
    const [value, setValue] = useState(val.attAccess);


    function onClick() {
        nav.accessEdit = false;
        setValue(attAccessChange(props.objAttVal));
        updateAttAccess(props.objAttVal, props.globalState, props.globalDispatch); 
    }         
    
    function onSubmit(o) {
        o.preventDefault();
        nav.accessEdit = false;
        if (value === val.attAccess) updateState(props);
        else { val.attAccess = value; updateAttAccess(props.objAttVal, props.globalState, props.globalDispatch); }
    }

    return(
        <Fragment>
            <div className = "pop-field-icon">
                <PopSvg svg={lockIcon(value)} fill="#86bbd8" onClick={onClick} />           
            </div>    
            { !nav.accessEdit && <PopViewAccess value={value} onClick={() => { nav.accessEdit = true; updateState(props);}}/> }
            {  nav.accessEdit && <PopEditAccess value={value} onSubmit={onSubmit} setValue={setValue}/> }
        </Fragment>  )
}



function PopAttValue(props) {
    if (!showValue(props)) return (
            <div className="pop-field-value">
                <p onClick = {props.onClickEdit}>&nbsp;&nbsp;</p> 
            </div> )
    else {
        let htmlType = props.objAttVal.att.htmlType;
        if (props.objAttVal.nav.isHeader && !props.objAttVal.val.attActive) htmlType = "text";
        switch (htmlType) {
            case 'file': return (
                <PopAttFileValue {...props}/> )
            case 'url': return (
                <PopAttUrlValue {...props}/> )
 /*           case 'qrcode': return (
                <PopAttQRValue {...props}/> ) */
            case 'location': return (
                <div className="pop-field-value">
                    <p onClick = {props.onClickEdit}>{props.objAttVal.val.attValue || '\u00A0'}</p> 
                    { locationUse(props.objAttVal) && <PopSvg className="pop-field-svg" svg="place" onClick={() => locationOnClick(props.objAttVal, props.globalState, props.globalDispatch)}/> }
                </div> )                
            default: return (
                <div className="pop-field-value">
                    <PopAttValueSvg { ...props }/>
                    <p onClick = {props.onClickEdit}>{convertObjAttValue(props.objAttVal) || '\u00A0'}</p> 
                    <PopAttChildObject { ...props } />
                </div> )
        }
    }
}



function PopAttView(props) {
    const { globalDispatch, globalState } = useContext(Context);
    const { nav, att } = props.objAttVal;
    
    const onClickSelect = () => {
        if (props.editMode) selectOnClick(props.objAttVal, globalState, globalDispatch);   
    } 
    const onClickEdit = () => {
        if (props.editMode) { 
            if ((att.attFunction === null || (att.attFunction !== null && att.attFunction.optional)) && 
                !nav.isHeader && !nav.objLink) editOnClick(props.objAttVal, globalState, globalDispatch);  
            else selectOnClick(props.objAttVal, globalState, globalDispatch);   
        }
    }
  
    const onChange = (e => { 
        uploadAttFile(props.objAttVal, e.target.files[0], globalState, globalDispatch);
    });      
    
    return (
       <div className = "pop-field" style = { { background: nav.selMode ? "#e7f0ff" : ""} }>     
            <PopAttSpace {...props} globalState = {globalState} globalDispatch = {globalDispatch}/>      
            <PopAttLabel {...props} onClick = {onClickSelect}/> 
            <PopAttValue {...props} onClickEdit = {onClickEdit} onClickSelect={onClickSelect} onChange={onChange} globalDispatch = {globalDispatch} globalState = {globalState}/> 
            { nav.isHeader && nav.showChilds && (att.attHasChilds > 0) && <PopAttTableClick {...props} globalDispatch = {globalDispatch} globalState = {globalState}/> }    
            { nav.accessMode && <PopAttAccess {...props} globalDispatch = {globalDispatch} globalState = {globalState}/> }
            { nav.relatedMode && <PopAttRelated {...props} globalDispatch = {globalDispatch} globalState = {globalState}/> }
            <PopAttVerified {...props}/>
            <PopAttShowChilds {...props} globalDispatch = {globalDispatch} globalState = {globalState}/>           
        </div>
    );   
}

function imageStyle(props) {
    switch(props.objAttVal.att.attClassName) {
        case "card": return { borderRadius: "10%" };                   
        default:     return { borderRadius: "50%" };
    }
}

function PopAttTableItemColumnValue(props) {
    const { objAttVal } = props;  

    switch(objAttVal.att.htmlType) {
        case "file": if (props.objAttVal.val.attShortFile) { return (<img style={imageStyle(props)} src={ FILE_LOCATION + props.objAttVal.val.attShortFile + "?"+ props.objAttVal.val.lastUpdate} loading="lazy" alt="profile" onClick={props.onClickImage}></img>) }
                     else                                  {  return(<PopSvg svg={objAttVal.obj.objType.objTypeSvg}/>); }
        case "select": if (!objAttVal) return (<p/>);
                       if (objAttVal.val.attValueSvg) return (<PopSvg svg = {objAttVal.val.attValueSvg} style={{"width": "30px" }} onClick={props.onClickSelect}/>);     
                       if (objAttVal.val.attValueShort) return (<p onClick={props.onClickSelect}>{objAttVal.val.attValueShort}</p>)           
                       return (<p>{objAttVal.val.attValue}</p>)   
        default:     return (<Fragment>
                                <PopAttChildObject { ...props } />
                                <p onClick={props.onClickSelect}>{convertObjAttValue(objAttVal) || '\u00A0'}</p>
                            </Fragment>)  
    }    
}

function ItemColumnObjAttVal(objAttVal, att) {
    if (objAttVal.att.attTable === att.attTable) return objAttVal;
    return objAttVal.childAtt.find(chiAtt => chiAtt.att.attTable === att.attTable); 
}


function PopAttTableItemColumn(props) {
    return (
        <div className="pop-field-table-value" style={{"flexBasis": props.att.flexBasis+"%"}}>
            <PopAttTableItemColumnValue {...props} objAttVal={ItemColumnObjAttVal(props.objAttVal, props.att)}/>
        </div>
    )
}



function PopAttTableHeaderColumn(props) {
    return (
        <div className="pop-field-table-header" style={{"flexBasis": props.att.flexBasis+"%"}} onClick={props.onClick}>
            <label>{isNull(props.att.attShort) ? props.att.attName : props.att.attShort}</label>
        </div>    
    )
}

function PopAttTableHeader(props) {
    const { globalDispatch, globalState } = useContext(Context);
    const { att, nav, childAtt } = props.objAttVal;

    const onClick = (att) => {
        if (att && att.attTable === 0 && props.editMode) {
            selectOnClick(props.objAttVal, globalState, globalDispatch);   
        }
        else {
            let ascending = 1;
            if (att.sorted) { att.sorted = false; ascending = -1; } else { att.sorted = true; ascending = 1; }
            childAtt.sort((a,b) => (compare(getObjAttValueToSort(ItemColumnObjAttVal(a,att)),getObjAttValueToSort(ItemColumnObjAttVal(b,att)))) ? ascending : (compare(getObjAttValueToSort(ItemColumnObjAttVal(b,att),ItemColumnObjAttVal(a,att))) ? -ascending : 0));    
        }
        globalDispatch( { type: 'update-state', state: globalState });
    }

    return (
        <div className = "pop-field" style = { { background: nav.selMode ? "#e7f0ff" : ""} }>     
            <PopAttSpace {...props} level={props.level} globalState = {globalState} globalDispatch = {globalDispatch}/>     
            { att.tableAtt.slice(0,att.attTableCount).map((att,index)=> {
                return (<PopAttTableHeaderColumn att={att} key = {index} onClick={() => onClick(att)}/> )
            })}              
          { /*  <PopAttTableClick {...props} globalDispatch = {globalDispatch} globalState = {globalState}/> */ }
            <PopAttShowChilds {...props} globalDispatch = {globalDispatch} globalState = {globalState}/>           
        </div>
    );   
}

function PopAttTableItems(props) {
    const { globalDispatch, globalState } = useContext(Context);
    const { nav, att } = props.objAttVal;
    
    const onClickSelect = () => {
        if (props.editMode) selectOnClick(props.objAttVal, globalState, globalDispatch);
    }
      
    const onClickImage = () => { attPhotoOnClick(props.objAttVal, globalState, globalDispatch) }


    return (
       <div className = "pop-field" style = { { background: nav.selMode ? "#e7f0ff"  : ""} }>     
            <PopAttSpace {...props} globalState = {globalState} globalDispatch = {globalDispatch}/>      
            { att.tableAtt.slice(0, att.attTableCount).map((att,index)=> {
                return (<PopAttTableItemColumn {...props} att={att} key = {index} globalDispatch = {globalDispatch} globalState = {globalState} onClickImage={onClickImage} onClickSelect={onClickSelect}/> )
            })}              
         { /*   <PopAttTableEmpty {...props}/> */ }
            <PopAttShowChilds {...props} globalDispatch = {globalDispatch} globalState = {globalState}/>           
        </div>
    );   
}


function PopAttTableView(props) {
    return (
        props.objAttVal.nav.isHeader ?
        <PopAttTableHeader {...props}/> :
        <PopAttTableItems {...props}/>
    );   
}

function PopAttEditDataList(props) {
    const onChange = e => props.setValue({ ...props.value, value: e.target.value });
    return (
        <Fragment>
            <input list = {attHtmlId(props.objAttVal)} type = {getHtmlType(props.objAttVal.att.htmlType)} name = {props.objAttVal.att.attName} value = {props.value.value} autoFocus onSubmit={props.onSubmit} onChange={onChange} />  
            <datalist id = {attHtmlId(props.objAttVal)}>
                { props.value.options.map((option,index) => {
                    return <option key={'val'+index}>{option}</option>
                })}        
            </datalist>
        </Fragment>
    )
}

function PopAttEditInput(props) {
    const onChange = e => props.setValue({ ...props.value, value: e.target.value });
    return (
        <input id = {attHtmlId(props.objAttVal)} type = {getHtmlType(props.objAttVal.att.htmlType)} step = {getHtmlStep(props.objAttVal.att.htmlType)} name = {props.objAttVal.att.attName} value = {props.value.value} autoFocus onSubmit={props.onSubmit} onChange={onChange} />  
    )
}

function PopAttEditSelect(props) {
    const onChange = e => {
      let index = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[index].text;
      props.setValue({ ...props.value, value: label, valueId: parseInt(e.target.value) })
    }
    return (
        <select id = {attHtmlId(props.objAttVal)} type = {props.objAttVal.att.htmlType} name = {props.objAttVal.att.attName} value = {props.value.valueId || ''} autoFocus onChange={onChange}>                
        <option value=''></option>
        { props.objAttVal.att.attValues && props.objAttVal.att.attValues.map(opt => {
        return <option key={'val'+opt.valueId} value={opt.valueId}>{opt.valueName}</option> } ) }
        { props.objAttVal.val.attValues && props.objAttVal.val.attValues.map(opt => {
        return <option key={'val'+opt.valueId} value={opt.valueId}>{opt.valueName}</option> } ) }
    </select>
    )
}

function PopAttEdit(props) {
    const { globalState, globalDispatch } = useContext(Context);
    const { att, val, nav } = props.objAttVal;
    const [value, setValue] = useState(initAtt(props.objAttVal));

   
    const onSubmit = e => {
        e.preventDefault();
        nav.editMode = false;
        nav.selMode = false;
        globalState.navigation.objAttVal = null;
        val.changed = valueChanged(props.objAttVal, value);
        if (!val.changed) globalDispatch( { type: 'update-state', state: globalState  });
        else { 
            switch (att.htmlType) {
                case "file": uploadAttFileLink(props.objAttVal, globalState, globalDispatch); break;
                case "select": if (val.objChild) updateObjChildValue(props.objAttVal, globalState, globalDispatch); 
                               else              updateObjValues(props.objAttVal, globalState, globalDispatch);
                               break; 
                default:  updateObjValues(props.objAttVal, globalState, globalDispatch);
            }
        }
    }
    return (
        <form className = "pop-field" style = { { background: "#e7f0ff" } } onSubmit={onSubmit}>
            <PopAttSpace {...props} onClick = {null}/>   
            <PopAttLabel {...props} onClick = {null}/>            
            <div className="pop-field-value">
            { {
                'select': <PopAttEditSelect {...props} onSubmit={onSubmit} setValue={setValue} value={value}/>,
                'input': <PopAttEditInput {...props} onSubmit={onSubmit} setValue={setValue} value={value}/>,
                'datalist': <PopAttEditDataList {...props} onSubmit={onSubmit} setValue={setValue} value={value}/>
            }[value.inputType] }
            </div>   
            <button className="pop-field-icon">
                <PopSvg svg="save" fill="#86bbd8"/>
            </button>             
        </form>
    )
}

function ShowPopAtt(props) {
   if (props.objAttVal.val.nullValue && (props.objAttVal.att.attUse === pop.attUse.shown)) return (props.showNull);
   if (props.objAttVal.val.nullValue && (props.objAttVal.att.attUse === pop.attUse.hidden)) return (props.showNull && props.objAttVal.obj.viewHidden);
   return true;
}

function isTable(props) {
    return (props.objAttVal.nav.isHeader && props.objAttVal.nav.isTable && props.objAttVal.nav.showChilds && !props.objAttVal.singleAtt) || 
           (props.objAttVal.nav.isItem && props.objAttVal.parentAtt.nav.isTable && !props.objAttVal.parentAtt.singleAtt);
}

function PopAtt(props) {
    return (
        ShowPopAtt(props) ?
        !props.objAttVal.nav.editMode ? 
        isTable(props) ?
        <PopAttTableView {...props}/> :
        <PopAttView {...props}/> : 
        <PopAttEdit {...props} /> : null)                                          
}


function PopAttComplex(props) {
    return (
        <div className="pop-field-complex">
            <PopAttParentChilds keyChilds = {'att'} {...props} />
        </div> );
}


function PopAttParentChilds(props) {
    const objAttVal  = props.objAttVal.singleAtt ? props.objAttVal.singleAtt :  props.objAttVal;
    
    return (
        <Fragment>
             <PopAtt {...props } objAttVal = {objAttVal}/>      
            { objAttVal.nav.showChilds ? objAttVal.childAtt.map(chiAtt => { return (
                <PopAttParentChilds key = {props.keyChilds+'_'+chiAtt.att.attId+'_'+chiAtt.val.attInst} {...props} level = { props.level + 1 } objAttVal = {chiAtt} keyChilds={props.keyChilds+'_'+chiAtt.att.attId+'_'+chiAtt.val.attInst}/>
                 ) }) : null }     
       </Fragment> );
}



function PopAttSimple(props) { 
    return (
            <PopAtt {...props }/>
    );
}

function PopCardAttribute(props) {
    return (
        <Fragment>
            { (props.objAttVal.childAtt.length > 0) && props.objAttVal.nav.showChilds ? 
                <PopAttComplex {...props } /> :
                <PopAttSimple {...props} /> }
        </Fragment>
    );
}

export default PopCardAttribute;