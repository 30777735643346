import { useReducer } from 'react';
import { initState } from './PopState';


const reducer = (state, action) => {
 
   console.log(action);
    switch (action.type) {
        case 'update-state':
            return { ...action.state };                   
        default:
            return state;
    }
}

const useGlobalState = () => {

    const [ globalState, globalDispatch ] =  useReducer(reducer, initState);
    return { globalState, globalDispatch};
}

export default useGlobalState;