import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';
import { selectObjValues } from './PopApiGlobal';
import { FILE_LOCATION } from './PopConst';


/*
function showInMapClicked(latlng) {
    window.open("https://maps.google.com?q="+latlng);
  };
*/

function PopMapMarkerProfile(props) {
    return (
    <svg className = "pop-map-marker" viewBox="0 0 24 24" onClick={props.onClick}>
        <path fill={props.fill} d="M 6.288 20.956 C 0.787 15.457 0 0 0 0 C 0 0 15.457 0.787 20.956 6.288 C 25.015 10.343 25.015 16.901 20.956 20.956 C 16.901 25.015 10.343 25.015 6.288 20.956 Z M 13.775 5.669 C 9.357 5.669 5.775 9.251 5.775 13.669 C 5.775 18.087 9.357 21.669 13.775 21.669 C 18.193 21.669 21.775 18.087 21.775 13.669 C 21.775 9.251 18.193 5.669 13.775 5.669 Z" />
        <circle cx="13.5" cy="13.5" r="8.3" fill="#fff"/>
        <mask id="myMask"> <circle cx="13.5" cy="13.5" r="8.3" fill="#fff"/> </mask> 
        { props.profile ? <image href={ FILE_LOCATION + props.profile} alt="profile" x="4.4" y="4.4" height="18.2" width="18.2" mask="url(#myMask)" /> : 
          <PopSvg svg={props.svg} className = "pop-map-marker-svg" viewBox="-12 -12 42 42" fill={props.fill}/>  }
    </svg> );
}

function PopMapMarker(props) {
    return (  
        <PopSvg className = "pop-map-marker-new" svg="marker" fill={props.fill}/>   
    )
}


function PopMap() {
    const { globalState, globalDispatch } = useContext(Context);
    const { navigation, data, config } = globalState;

 
    const onChange = (e) => {
        console.log(e);
        navigation.mapBounds = e.bounds;
        globalDispatch( { type: 'update-state', state: globalState })
    };

    const handleApiLoaded = (map, maps) => {
        let bounds = map.getBounds();
        data.mapPois.forEach(poi => bounds.extend({ lat: parseFloat(poi.objLat), lng: parseFloat(poi.objLng) }));
        data.objects.filter(obj => obj.objLat !== null && obj.objLng !== null).forEach(poi => bounds.extend({ lat: parseFloat(poi.objLat), lng: parseFloat(poi.objLng) }));
        map.fitBounds(bounds);
        navigation.showMarkers = true;      
        globalDispatch( { type: 'update-state', state: globalState })
        }
    

    return (
        navigation.showMap ?
        <div className = "pop-map">
            <div className = "pop-map-header">
                <PopSvg svg="map"/>
                <h3>{popCaption(config,'map').captionLong}</h3>
                <PopSvg svg={navigation.showMapDetail ? "hide": "show"} onClick= {() => { navigation.showMapDetail = !navigation.showMapDetail; globalDispatch( { type: 'update-state', state: globalState });}}/>
                <PopSvg svg="close" onClick = {() => { navigation.showMap = false; navigation.showMapDetail = false; globalDispatch( { type: 'update-state', state: globalState });}}/>
            </div>                     
            { navigation.showMapDetail && 
            <div className = "pop-map-content" key={navigation.mapUpdate}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
                    options={{mapTypeControl: true}}
                    yesIWantToUseGoogleMapApiInternals={true}            
                    center={navigation.mapCenter}
                    zoom={navigation.mapZoom}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    onChange={onChange}
                    onClick= {(e) => { if (navigation.editMode) { navigation.mapPoi = { lat: e.lat, lng: e.lng }; navigation.mapPoiNew = true; globalDispatch( { type: 'update-state', state: globalState }); } }}>                   
                    { navigation.showSearch && navigation.showMarkers && data.mapPois.map((coord, index) => 
                        { return(<PopMapMarkerProfile key={index} profile = {coord.objProfile} svg = {coord.objSvg} lat={coord.objLat} lng={coord.objLng} fill="#7CB400" onClick={ 
                            () => selectObjValues(coord.objTypeId, coord.objId, globalState, globalDispatch)}/>)})}
                    { data.objects.filter(obj => obj.objLat !== null && obj.objLng !== null).map((coord, index) => 
                        { return(<PopMapMarkerProfile key={index} profile = {coord.objProfile} svg = {coord.objSvg} lat={coord.objLat} lng={coord.objLng} fill="#2B6BA5" onClick={ 
                            () => selectObjValues(coord.objTypeId, coord.objId, globalState, globalDispatch)}/>)})}
                    { /* mapPoi && editMode && <PopMapMarker profile = "poi-blue.svg" lat={mapPoi.lat} lng={mapPoi.lng}/> */ }
                    { navigation.mapPoi && navigation.mapPoiNew && <PopMapMarker lat={navigation.mapPoi.lat} lng={navigation.mapPoi.lng} fill="#7CB400"/> }
                </GoogleMapReact>
            </div>  }
        </div> : null
    );
}


export default PopMap;
