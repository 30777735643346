import React, { useContext, Fragment } from 'react';
import Context from './Context';
import { PopSvg } from './PopSvg'


function stateChange(state) {
    if (state === undefined || state === null) return false;
    return !state;
}


function stateObjCardChilds(state, childs) {
    if (childs !== undefined) childs.forEach(child => { child.showDetail = state; } );
}

function checkObjCardParent(objCard) {
    if (objCard.childCards.filter(child => child.showDetail).length === objCard.childCards.length) return true;
    if (objCard.childCards.filter(child => !child.showDetail).length === objCard.childCards.length) return false;
    return undefined;
}



function stateIcon(state) {
    switch (state) {
        case undefined: return "check-alt";
        case null: return "check-alt";
        case true: return "check";
        case false: return "uncheck";
        default: return "uncheck";
    }
}


function PopFolderItem(props) {
    return (
        <div className = "pop-folder-item">
           {[...Array(props.level-1)].map((e, i) => { return (<PopSvg svg="empty" fill="#fff" key={i}/>)})}
           <PopSvg svg={props.objCard.card.cardSvg}/>
           <p>{props.objCard.card.cardName}</p>
           { props.objCard.childCards !== undefined && 
           <Fragment>
                <PopSvg svg={stateIcon(checkObjCardParent(props.objCard))} fill="#b2c9de" onClick={o =>{ props.objCard.checked = stateChange(props.objCard.checked); stateObjCardChilds(props.objCard.checked, props.objCard.childCards); props.globalDispatch( { type: 'update-state', state: props.globalState }); } }/> 
                <PopSvg svg={props.objCard.showFolders ? "hide" : "show"} onClick={o =>{ props.objCard.showFolders = !props.objCard.showFolders; props.globalDispatch( { type: 'update-state', state: props.globalState }); } }/> 
           </Fragment> }
           { props.objCard.childCards === undefined && 
           <Fragment>
                { /* props.object.editMode && <PopSvg svg={folderCompleted(props.objCard.attCount,props.objCard.attCountAll)} fill="#b2c9de" onClick={o => { props.objCard.showNull = !props.objCard.showNull; props.globalDispatch( { type: 'update-state', state: props.globalState }); }}/> */}
                <PopSvg svg={props.objCard.showDetail ? "check" : "uncheck"} fill="#b2c9de" onClick={o =>{ props.objCard.showDetail = !props.objCard.showDetail; props.objCard.showNull = props.objCard.showDetail; props.globalDispatch( { type: 'update-state', state: props.globalState }); } }/>    
           </Fragment>
            }                     

        </div>
    )
}


function PopFolderItems(props) {
    return(
        <Fragment>
             <PopFolderItem {...props} key = {props.keyChilds} />
             { props.objCard.showFolders && props.objCard.childCards.map(childCard => { return (
                <PopFolderItems {...props} level = {props.level + 1} key = {props.keyChilds+'-'+childCard.card.cardId} keyChilds = {props.keyChilds+'-'+childCard.card.cardId} objCard = {childCard} />)} )}
        </Fragment>

    )

}


function PopObjectFolders(props) {
    const { globalState, globalDispatch } = useContext(Context);
    
    return (
        props.object.showFolders &&
        <div className = "pop-folders">
            <div className = "pop-folder-items">
            { props.object.objCards.filter(objCard => objCard.card.cardParent === 0).map(objCard => { return (
                <PopFolderItems level = {1} key = {props.keyChilds+'-'+objCard.card.cardId} keyChilds = {props.keyChilds+'-'+objCard.card.cardId} object = {props.object} objCard = {objCard} globalState = {globalState} globalDispatch = {globalDispatch}/>
                ) } ) }
            </div>    
        </div>
    );

}

export { PopObjectFolders };